body:not(.menu--open) {
    .sticky-info {
        z-index: 20;
        transition: 0s all;
    }
}

.sticky-info {
    position: fixed;
    top: 50%;
    right: 0;
    background-color: #fff;
    transform: translateY(-50%) translateX(110%);
    z-index: 1;
    width: 100%;
    max-width: 325px;
    border-radius: 0 0 0 50px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.16);
    border: 1px solid #c6c6c6;
    transition: 0.3s all;

    &.active {
        transform: translateY(-50%) translateX(0);
    }

    &__button {
        color: #fff;
        position: absolute;
        top: 0;
        left: 0;
        height: 50px;
        width: 50px;
        background-color: $primarycolor;
        border-radius: 100%;
        border: 0;
        transform: translateY(-50%) translateX(-50%);
        display: flex;
        align-items: center;
        justify-content: center;

        &:focus {
            outline: none;
        }

        svg {
            height: 25px;
            width: 25px;
        }
    }

    &__image {
        height: 200px;
        width: 200px;
        border-radius: 100%;
        overflow: hidden;
        position: relative;
        display: flex;
        justify-content: center;
        margin-bottom: 40px;
        margin-left: auto;
        margin-right: auto;

        img {
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            position: absolute;
            object-fit: cover;
            object-position: center;
        }
    }

    &__wrapper {
        padding: 70px 60px;

        p {
            font-size: 19px;
            line-height: 1.5;

            strong {
                display: block;
            }
        }
    }

    &__contact {
        display: flex;
        justify-content: center;
        flex-direction: row;
        margin-top: 10px;
    }

    &__link {
        color: #fff;
        height: 60px;
        width: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $secondarycolor;
        border-radius: 10px;
        margin: 0 10px;

        &:hover {
            color: #fff;
            background-color: $primarycolor;
        }

        svg {
            width: 25px;
            height: 25px;
        }
    }
}

.sticky-info__more {
    color: #fff;
    position: fixed;
    bottom: 0;
    right: 0;
    height: 50px;
    width: 50px;
    background-color: $primarycolor;
    border-radius: 100%;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    margin-right: 15px;
    visibility: hidden;
    opacity: 0;
    transition: background-color 0.3s all;
    animation: bounce 4s infinite;
    z-index: 1;

    &.active {
        visibility: visible;
        opacity: 1;
    }

    &:focus {
        outline: none;
    }

    svg {
        height: 25px;
        width: 25px;
    }

    span {
        display: none;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
    }
}

@keyframes bounce {
    10%,
    20%,
    30%,
    40%,
    50% {
        transform: translateY(0);
    }

    15% {
        transform: translateY(-10px);
    }

    25% {
        transform: translateY(-5px);
    }

    35% {
        transform: translateY(-2.5px);
    }

    45% {
        transform: translateY(-1px);
    }
}

@media screen and (max-width: 991px) {
    .sticky-info__more {
        width: auto;
        height: auto;
        padding: 10px 20px;
        border-radius: 8px;

        svg {
            height: 25px;
            width: 25px;
            margin-right: 15px;
        }

        span {
            display: block;
        }
    }
}

@media screen and (max-width: 767px) {
    .sticky-info {
        &__image {
            height: 100px;
            width: 100px;
        }

        &__wrapper {
            padding: 30px;

            p {
                font-size: 16px;
            }
        }

        &__link {
            height: 50px;
            width: 50px;

            svg {
                width: 20px;
                height: 20px;
            }
        }
    }

    .sticky-info__more {
        padding: 10px 20px;
        border-radius: 8px;

        &.active {
            visibility: visible;
            opacity: 1;
        }

        &:focus {
            outline: none;
        }

        svg {
            height: 25px;
            width: 25px;
        }
    }
}
