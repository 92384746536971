@media screen and (max-width: 1950px) {
    .banner__content {
        .right {
            .banner__content__inner {
                padding-right: 100px;
            }
        }

        .left {
            .banner__content__inner {
                padding-left: 100px;
            }
        }
    }
}

@media screen and (max-width: 1499px) {
    .vacancy__filter h4 {
        font-size: 14px;
        margin-right: 20px;
    }

    .vacancy__filter form > ul > li {
        flex-direction: column;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .vacancy__filter .sf-field-submit {
        justify-content: flex-end;
    }

    .vacancy__filter .sf-field-submit::after {
        background-position: center 24px;
    }
    
    .template-vacatures-data .vacancy__filter form > ul {
        gap: 0 20px;
        justify-content: center;
    }
}
