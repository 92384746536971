.single__img__nieuws {
    position: relative;
    display: block;
    width: 100%;
    max-width: 800px;
    margin-bottom: 25px;
    overflow: hidden;

    h1 {
        position: absolute;
        bottom: 30px;
        left: 0;
        color: #fff;
        padding: 0 30px;
        width: 100%;
        text-shadow: 0 0 12px rgba(0, 0, 0, 0.36);
        margin-bottom: 0;
    }

    img {
        border-radius: $block-radius;
    }
}

.news__label__cat {
    position: absolute;
    right: 0;
    top: 0;

    span {
        position: absolute;
        left: 63px;
        top: 37px;
        font-size: 18px;
        font-weight: 700;
        text-transform: uppercase;
        transform: rotate(40deg);
        color: #197611;
    }
}

.page-wrap--news {
    .block__summary {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        max-height: 75px;
    }

    .block__readmore {
        margin-top: auto;
    }
}

@media screen and (max-width: 1199px) {

}

@media screen and (max-width: 991px) {

}

@media screen and (max-width: 767px) {
    .page-wrap--news {
        .block__summary {
            height: inherit;
        }
    }

    .single__img__nieuws {
        h1 {
            bottom: 20px;
        }
    }

    .single__text {
        margin-bottom: 0;
    }
}

@media screen and (max-width: 575px) {

}
