@media screen and (max-width: 767px) {
    body {
        font-size: 15px;

        h1 {
            font-size: 22px;
        }
    }

    body.archive-vacatures-data .cta {
        padding-top: 30px;
        padding-bottom: 10px;
    }

    .layer {
        padding: 40px 0;
    }

    .front__content__location {
        margin-top: 18px;
    }

    .vacancy__filter form > ul {
        flex-direction: column;
        gap: 20px 0 !important;
    }

    .ajax-filter {
        margin-top: 40px;
    }

    .vacancy__filter form > ul > li,
    .vacancy__filter .sf-field-post-meta-vacature_hour_filter {
        max-width: 100%;
        flex: 0 0 100%;
    }

    .vacancy__filter--title {
        margin: 50px 0 30px;
        font-size: 22px;
    }

    .vacancy__filter .sf-meta-range-slider {
        margin-left: -20px;
        width: 96%;
    }

    .vacancy__filter .sf-field-submit {
        margin: 0 auto;
    }

    .vacancy__filter .sf-field-submit input {
        font-size: 18px;
        padding: 15px 32px;
    }

    .vacancy__filter.vacancy__filter--taxonomy {
        margin: 40px 0;
    }

    .front__location {
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 32px 0 4px 0;
        border-radius: 0;

        &::after {
            position: absolute;
            top: 0;
            left: -20px;
            width: 20px;
            height: 100%;
            content: '';
            background-color: $primarycolor;
        }

        &::before {
            position: absolute;
            top: 0;
            right: -20px;
            width: 20px;
            height: 100%;
            content: '';
            background-color: $primarycolor;
        }
    }

    .front__location__title {
        max-width: 250px;
    }

    .front__location__title svg {
        width: 50px;
        min-width: 50px;
        height: 50px;
        min-height: 50px;
    }

    .front__location__search {
        margin-top: 37px;
    }

    .wpgmp_map_container div.categories_filter select {
        min-width: 100%;
        height: 50px;
        margin-right: 0;
        margin-bottom: 6px;
        padding: 3px 37px 5px 16px;
        font-size: 16px;
        border-radius: 3px;
        background-position: center right 12px;
        background-size: 11px 22px;
    }

    .wpgmp_map_container div.wpgmp_search_form input.wpgmp_search_input {
        height: 50px;
        padding: 3px 37px 5px 16px;
        font-size: 16px;
        border-radius: 3px;
    }

    .wpgmp_map_container div.categories_filter div[data-container=wpgmp-filters-container] {
        width: 100%;
    }

    .front__location__title__txt {
        .title {
            font-size: 18px;
        }

        .subtitle {
            font-size: 15px;
        }
    }

    .wpgmp_map_container div.categories_filter .select-wrapper-radius {
        width: 49%;

        select {
            min-width: 100%;
            padding-left: 35px;
        }
    }

    .wpgmp_map_container div.categories_filter .wpgmp_search_form {
        width: 49%;
        margin-right: 2%;
        margin-bottom: 6px;

        input {
            width: 100%;
        }
    }

    .wpgmp_map_container div.categories_filter .select-wrapper-radius::before {
        top: 17px;
        left: 12px;
        width: 15px;
        height: 15px;
        background-size: 15px 15px;
    }

    .wpgmp_map_container .btn--zoeklocaties {
        width: 100%;
    }

    .locations__content .wpgmp_map_container .wpgmp_filter_wrappers {
        margin-top: 20px;
        margin-bottom: 8px;
    }

    .wpgmp_map_container .location__block {
        flex-wrap: wrap;
        padding: 17px 19px;
        border-radius: 3px;
    }

    .wpgmp_map_container .location__block__content {
        width: calc(100% - 54px);
        padding-left: 20px;
    }

    .wpgmp_map_container .location__block__title a {
        font-size: 17px;
    }

    .wpgmp_map_container .location__block__arrow {
        display: inline-block;
        order: 4;
        width: 100%;
        margin-top: 5px;
        margin-left: auto;
        padding-right: 15px;
        padding-left: 0;
        font-size: 15px;
        text-align: right;

        &::after {
            width: 6px;
            height: 16px;
            background-size: 6px 16px;
        }
    }

    .wpgmp_map_container .location__block__txt {
        margin-top: 14px;
    }

    .circle__top--front {
        svg {
            width: 40px;
            height: 200px;

            &.cthm {
                display: block;
            }

            &.cth {
                display: none;
            }
        }
    }

    .banner__content {
        margin-bottom: 0;

        .btn {
            margin-top: 20px;
        }
    }

    .banner__content__inner {
        margin-top: -120px;
        padding: 15px 25px !important;
    }

    .banner__title {
        font-size: 17px;
    }

    .menu ul li,
    .menu ul li a {
        color: #fff;
    }

    .contact__details--location {
        margin-top: 20px;
    }
}
