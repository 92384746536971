.locaties-template-default {
    .footer {
        margin-top: 0 !important;
    }
}

.footer {
    position: relative;
    margin-top: 70px;
    padding: 90px 0 80px;
    background: $secondarycolor;

    &::before {
        position: absolute;
        z-index: 0;
        right: 0;
        bottom: 0;
        display: inline-block;
        width: 17.3vw;
        height: 35.4vw;
        content: "";
        pointer-events: none;
        background: url(../../svg/shapes-right.svg) no-repeat right bottom;
        background-size: contain;
    }
}

.footer__logo {
    width: 27%;

    svg {
        width: 100%;
        max-width: 170px;
    }
}

.footer .container-fluid {
    display: flex;
}

.footer__social {
    display: flex;
    margin-top: 9px;

    a {
        position: relative;
        top: 0;

        &:not(:last-child) {
            margin-right: 26px;
        }

        &:hover {
            top: -3px;
        }
    }

    svg {
        width: 22px;
        height: 22px;
        color: #fff;
    }
}

.footer__links {
    margin-top: 30px;
    font-size: 14px;

    ul {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            position: relative;

            a {
                padding-right: 28px;
                color: $white;

                &:hover {
                    color: #beeff2;
                }
            }

            &:not(:last-child)::after {
                position: absolute;
                right: 14px;
                display: inline-block;
                content: "|";
                color: $white;
            }
        }
    }
}

.footer__copyright {
    font-size: 13px;
    font-weight: 400;
    color: #beeff2;

    a {
        color: #beeff2;

        &:hover {
            color: #fff;
        }
    }
}

@media screen and (max-width: 1599px) {
    .footer {
        margin-top: 55px;
        padding: 80px 0;

        &::before {
            width: 11vw;
            height: 22vw;
        }
    }
}

@media screen and (max-width: 1199px) {
    .footer__logo {
        width: 200px;
        padding-right: 40px;
    }

    .footer {
        margin-top: 20px;
        padding: 70px 0;

        &::before {
            display: none;
        }
    }
}

@media screen and (max-width: 991px) {
    .footer {
        margin-top: 45px;
        padding: 50px 0;
    }

    .footer .container-fluid {
        align-items: center;
        flex-direction: column-reverse;
    }

    .footer__rightcol {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .footer__social {
        margin-top: 0;
    }

    .footer__copyright {
        margin-top: 22px;
        text-align: center;
    }

    .footer__links {
        ul {
            justify-content: center;
        }
    }

    .footer__logo {
        margin-top: 20px;
    }
}

@media screen and (max-width: 767px) {
    .footer {
        margin-top: 40px;
        padding: 50px 0;
    }
}

@media screen and (max-width: 575px) {
    .footer {
        margin-top: 0;
        padding: 40px 0;
    }

    .footer__logo {
        max-width: 97px;
        padding: 0;

        img {
            max-width: 100%;
        }
    }

    .footer__links {
        margin-top: 35px;
    }

    .footer__copyright {
        margin-top: 28px;
    }
}
