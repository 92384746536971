.banner {
    position: relative;

    iframe {
        display: table;
    }

    video {
        width: 100%;
        height: auto;
    }

    img {
        width: 100%;
    }

    .slick-slide {
        img {
            border-radius: 0;
        }
    }

    .slick-arrow {
        top: 50%;
        transform: translateY(-50%);
    }

    .slick-prev {
        left: 50px;
    }

    .slick-next {
        right: 50px;
    }
}

.slick-dotted {
    &.slick-slider {
        margin-bottom: 0;
    }
}

.slick-dots {
    bottom: 50px;

    li {
        width: 13px;
        height: 13px;
        margin: 0 3px;

        button {
            width: 13px;
            height: 13px;
            padding: 0;

            &::before {
                width: 13px;
                height: 13px;
                content: "";
                line-height: 20px;
                text-align: center;
                opacity: 1;
                border: 2px solid #fff;
                border-radius: 50%;
            }
        }

        &.slick-active {
            button {
                &::before {
                    opacity: 1;
                    background-color: #fff;
                }
            }
        }
    }
}

.banner__slider {
    line-height: 0;
}

.banner__content {
    position: absolute;
    top: 0;
    left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #fff;
    -webkit-box-align: center;
    -ms-flex-align: center;

    .container-fluid {
        max-width: 1720px;
    }

    .btn {
        margin-top: 30px;
    }

    .row {
        &.center {
            justify-content: center !important;
            text-align: center;
        }

        &.right {
            justify-content: flex-end !important;
            text-align: right;
        }
    }
}

.banner--sub {
    .banner__content {
        top: -63px;
    }
}

.banner__title {
    font-size: 42px;
    font-weight: 600;
    line-height: 1.4;
}

.banner__text {
    display: block;
    margin-top: 10px;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.4;
}

//.banner__content {
//    position: absolute;
//    left: 0;
//    bottom: 100px;
//    display: flex;
//    align-items: center;
//    justify-content: center;
//    flex-direction: column;
//    text-align: center;
//    width: 100%;
//    padding: 0 30px;
//    color: #fff;
//
//    .btn {
//        margin-top: 25px;
//    }
//}
//
//.banner__title {
//    font-size: 40px;
//    font-weight: 700;
//    line-height: 1.2;
//    text-shadow: 0 0 12px rgba(0, 0, 0, 0.29);
//    text-transform: uppercase;
//}
//
//.banner__subtitle {
//    font-size: 24px;
//    font-weight: 400;
//    text-shadow: 0 0 12px rgba(0, 0, 0, 0.29);
//}

@media screen and (max-width: 1199px) {
    .banner {
        iframe {
            max-height: 350px;
        }
    }
}

@media screen and (max-width: 767px) {
    //.banner__content {
    //    display: none;
    //}

    .slick-dots {
        bottom: 20px;
    }

    .banner {
        iframe {
            max-height: 230px;
        }
    }
}
