.single-jaarverslagen {
    .footer {
        margin-top: 0;
    }

    .banner__title {
        font-size: 48px;

        @media screen and (max-width: 1199px) {
            font-size: 40px;
        }

        @media screen and (max-width: 991px) {
            font-size: 36px;
        }

        @media screen and (max-width: 767px) {
            font-size: 28px;
        }
    }
}

.quickto {
    position: relative;
    z-index: 2;
    margin-top: 78px;
    margin-bottom: 35px;

    @media screen and (min-width: 991px) {
        max-width: 1310px;
        margin: -62.5px auto 0 auto;
        display: flex;
        align-items: center;
        z-index: 2;
        background: white;
        border-radius: 50px;
        min-height: 125px;
        padding: 16px 32px;
        box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
    }

    .quickto__holder {
        position: relative;
        flex: 1;
    }

    .quickto__select {
        min-width: 100%;
        height: 50px;
        margin-right: 0;
        padding: 3px 37px 5px 16px;
        font-size: 16px;
        margin-bottom: 0;
        border-radius: 3px;
        background-position: center right 12px;
        background-size: 11px 22px;
        position: relative;
        width: 100%;
        margin-top: 0;
        font-weight: 300;
        text-transform: none;
        text-align: left;
        color: #464646;
        border: 1px solid #f2f2f2;
        background-color: #f2f2f2;
        background-image: url(data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhbCIgZGF0YS1pY29uPSJhbmdsZS1kb3duIiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtYW5nbGUtZG93biBmYS13LTgiIHJvbGU9ImltZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMjU2IDUxMiI+PHBhdGggZmlsbD0iIzE5NzYxMSIgZD0iTTExOS41IDMyNi45TDMuNSAyMDkuMWMtNC43LTQuNy00LjctMTIuMyAwLTE3bDcuMS03LjFjNC43LTQuNyAxMi4zLTQuNyAxNyAwTDEyOCAyODcuM2wxMDAuNC0xMDIuMmM0LjctNC43IDEyLjMtNC43IDE3IDBsNy4xIDcuMWM0LjcgNC43IDQuNyAxMi4zIDAgMTdMMTM2LjUgMzI3Yy00LjcgNC42LTEyLjMgNC42LTE3LS4xeiI+PC9wYXRoPjwvc3ZnPgo=);
        background-repeat: no-repeat;

        @media screen and (min-width: 991px) {
            display: none;
        }

        &.toggled + .quickto__dropdown {
            display: flex;
        }
    }

    .quickto__dropdown {
        @media screen and (max-width: 991px) {
            top: 100%;
            width: 100%;
            height: max-content;
            border-radius: 3px;
            background: #f2f2f2;
            row-gap: 6px;
            box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
            padding: 15px;
            bottom: 0;
            position: absolute;
            display: none;
            flex-direction: column;
            align-items: start;

            svg {
                fill: #464646;
            }
        }

        @media screen and (min-width: 992px) {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            column-gap: 16px;
            row-gap: 16px;
        }
    }

    .quickto__dropdown button {
        @media screen and (max-width: 991px) {
            background: none;
            border: none;
            padding: 0;
            color: #464646;
            font-weight: normal;
            font-size: 16px;
        }

        @media screen and (min-width: 992px) {
            margin: 0;
            width: max-content;
            font-size: 16px;
            display: inline-flex;
            column-gap: 10px;
            align-items: center;
        }

        @media screen and (min-width: 1200px) {
            font-size: 18px;
        }
    }
}

.intro {
    max-width: 1310px;
    margin: 0 auto 78px auto;
    position: relative;
    z-index: 1;

    @media screen and (min-width: 991px) {
        margin-top: 78px;
    }

    .expandable-content {
        max-height: 174px;
        font-size: 16px;

        @media screen and (min-width: 1200px) {
            font-size: 18px;
        }

        @media screen and (min-width: 768px) {
            max-height: 374px;
        }

        &::before {
            background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
        }
    }
}

.specialmoment__content__text {
    .expandable-content {
        max-height: 110px;

        @media screen and (min-width: 1200px) {
            max-height: 124px;
        }

        &::before {
            background: linear-gradient(0deg, rgba(249, 250, 251, 1) 0%, rgba(249, 250, 251, 0) 100%);
        }
    }
}

.expandable-content {
    margin-bottom: 26px;
    overflow: hidden;
    transition: 0.3s ease-in-out max-height;
    position: relative;

    :last-child {
        margin-bottom: 0;
    }

    &::before {
        content: '';
        transition: 0.3s ease-in-out opacity;
        height: 48px;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        opacity: 0;
    }

    &.expandable::before {
        opacity: 1;
    }

    &-toggler {
        display: inline-flex;
        column-gap: 10px;
        align-items: center;

        svg {
            transition: 0.3s ease-in-out transform;
        }

        &.toggled svg {
            transform: rotate(-180deg);
        }
    }
}

.specialmoments {
    text-align: center;
    overflow-y: clip;
    overflow-x: clip;
    padding-top: 56px;
    padding-bottom: 56px;
    position: relative;
    /* stylelint-disable */
    scroll-margin-top: 300px;
    /* stylelint-enable */

    &::before {
        content: "";
        background: #f9fafb;
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &--layer {
        &::before {
            display: none;
        }
    }

    @media screen and (min-width: 1200px) {
        padding-top: 129px;
        padding-bottom: 135px;
    }

    &__holder {
        display: grid;

        @media screen and (min-width: 768px) {
            row-gap: 45px;
        }

        @media screen and (min-width: 1200px) {
            row-gap: 60px;
        }
    }

    &__title {
        font-size: 30px;
        margin-bottom: 32px;

        @media screen and (min-width: 1200px) {
            font-size: 42px;
            margin-bottom: 88px;
        }
    }

    .specialmoment {
        display: grid;

        @media screen and (max-width: 767px) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
            padding-left: 60px;
            row-gap: 28px;
            margin-bottom: 32px;

            &:last-child {
                margin-bottom: 0;
            }

            .specialmoment__media,
            .specialmoment__content {
                &[data-animation-reveal-type="translate"] {
                    &[data-animation-reveal-from="left"] {
                        transform: translate(#{$revealDistance}, 0);
                    }

                    &[data-animation-reveal-from="right"] {
                        transform: translate(#{$revealDistance}, 0);
                    }
                }
            }
        }

        @media screen and (min-width: 768px) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
            column-gap: 96px;
        }

        @media screen and (min-width: 1200px) {
            column-gap: 218px;
        }

        &:nth-child(even) {
            @media screen and (min-width: 768px) {
                .specialmoment__media {
                    order: 2;
                }
            }

            .specialmoment__content {
                order: 1;
                text-align: left;

                &__title--lines {
                    position: absolute;
                    z-index: -10;
                    top: 50%;
                    left: -38px;
                    transform: translateY(-50%);

                    @media screen and (min-width: 768px) {
                        left: initial;
                        right: -48px;
                    }

                    @media screen and (min-width: 1200px) {
                        right: -109px;
                    }

                    &::before {
                        content: '';
                        height: 7px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        background: rgb(243, 244, 246);

                        @media screen and (max-width: 767px) {
                            width: 38px;
                            left: 0;
                            transform: translate(0, -50%);
                            background: linear-gradient(90deg, rgba(243, 244, 246, 1) 0%, rgba(243, 244, 246, 1) 50%, rgba(243, 244, 246, 0) 100%);
                        }

                        @media screen and (min-width: 768px) {
                            width: 96px;
                            transform: translate(-50%, -50%);
                            background: linear-gradient(90deg, rgba(243, 244, 246, 0) 0%, rgba(243, 244, 246, 1) 50%, rgba(243, 244, 246, 0) 100%);
                        }

                        @media screen and (min-width: 1200px) {
                            width: 218px;
                        }
                    }

                    &::after {
                        content: '';
                        border: solid 4px $primarycolor;
                        width: 22px;
                        height: 22px;
                        background: white;
                        border-radius: 999px;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
            }
        }

        &:nth-child(odd) {
            .specialmoment__media {
                order: 1;
            }

            .specialmoment__content {
                order: 2;
                text-align: left;

                &__title--lines {
                    position: absolute;
                    top: 50%;
                    left: -38px;
                    transform: translateY(-50%);

                    @media screen and (min-width: 768px) {
                        left: -48px;
                    }

                    @media screen and (min-width: 1200px) {
                        left: -109px;
                    }

                    &::before {
                        content: '';
                        height: 7px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        background: rgb(243, 244, 246);

                        @media screen and (max-width: 767px) {
                            width: 38px;
                            transform: translate(0, -50%);
                            left: 0;
                            background: linear-gradient(90deg, rgba(243, 244, 246, 1) 0%, rgba(243, 244, 246, 1) 50%, rgba(243, 244, 246, 0) 100%);
                        }

                        @media screen and (min-width: 768px) {
                            width: 96px;
                            transform: translate(-50%, -50%);
                            background: linear-gradient(90deg, rgba(243, 244, 246, 0) 0%, rgba(243, 244, 246, 1) 50%, rgba(243, 244, 246, 0) 100%);
                        }

                        @media screen and (min-width: 1200px) {
                            width: 218px;
                        }
                    }

                    &::after {
                        content: '';
                        border: solid 4px $primarycolor;
                        width: 22px;
                        height: 22px;
                        background: white;
                        border-radius: 999px;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
            }
        }

        .specialmoment__media {
            overflow: hidden;
            border-radius: 50px;
            box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1);
            /* stylelint-disable */
            aspect-ratio: 16/9;
            /* stylelint-enable */

            a {
                display: flex;
                position: relative;
            }

            img,
            video {
                width: 100%;
                height: 100%;
                object-fit: cover;
                display: inline-block;
            }

            .slick-arrow {
                display: flex;
                width: 27px;
                align-items: center;
                top: 50%;
                transform: translateY(-50%);
            }

            .slider__play {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 82px;
                height: 82px;
                background: $primarycolor;
                border-radius: 999px;
            }

            .slick-slider {
                height: 100%;
            }
        }

        .specialmoment__content {
            margin-top: auto;
            margin-bottom: auto;
            font-size: 18px;

            .specialmoment__content__title {
                font-size: 24px;
                font-weight: 700;
                margin-bottom: 16px;
                position: relative;

                @media screen and (min-width: 1200px) {
                    margin-bottom: 27px;
                    font-size: 36px;
                }

                * {
                    margin-bottom: 0;
                }

                &--verticalline {
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    height: 9999px;
                    width: 7px;
                    background: #f3f4f6;
                }
            }

            .specialmoment__content__text {
                font-size: 16px;
                font-weight: 400;

                @media screen and (min-width: 1200px) {
                    font-size: 18px;
                }
            }
        }
    }
}

.ourchildcares {
    padding: 64px 0;
    text-align: center;
    position: relative;
    z-index: 1;
    /* stylelint-disable */
    scroll-margin-top: 300px;
    /* stylelint-enable */

    @media screen and (min-width: 992px) {
        padding: 96px 0;
    }

    &__title {
        font-size: 30px;
        margin-bottom: 26px;

        @media screen and (min-width: 1200px) {
            font-size: 42px;
        }
    }

    &__content {
        max-width: 924px;
        margin: 0 auto;
        font-size: 16px;
        margin-bottom: 64px;

        @media screen and (min-width: 1200px) {
            font-size: 18px;
        }

        :last-child {
            margin-bottom: 0;
        }
    }

    .ourchildcare__media {
        &__title {
            color: $primarycolor;
            position: relative;
            z-index: 1;

            &::before {
                content: "";
                height: 1px;
                width: 100%;
                background: #e5e7eb;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }

            &__holder {
                display: flex;
                position: relative;
                align-items: center;
                width: max-content;
                max-width: 100%;
                text-align: left;
                column-gap: 20px;
                background: white;
                padding-right: 12px;

                @media screen and (min-width: 640px) {
                    padding: 0 24px;
                    margin: 0 auto;
                    text-align: center;
                }

                @media screen and (min-width: 992px) {
                    padding: 0 55px;
                }

                span {
                    font-size: 24px;
                    font-weight: bold;
                    line-height: 1;

                    @media screen and (min-width: 992px) {
                        font-size: 30px;
                    }
                }

                img {
                    filter: invert(60%) sepia(9%) saturate(2925%) hue-rotate(68deg) brightness(97%) contrast(80%);
                    min-height: 36px;
                    max-height: 36px;
                    width: auto;
                }
            }
        }
    }

    &__holder > div {
        margin-bottom: 64px;

        @media screen and (min-width: 992px) {
            margin-bottom: 92px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .ourchildcare__items {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        row-gap: 30px;
        column-gap: 30px;
        margin-top: 37px;

        @media screen and (min-width: 640px) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @media screen and (min-width: 1600px) {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }

        .ourchildcare__content {
            background: white;
            border: solid 1px #e5e7eb;
            border-radius: 15px;
            display: flex;
            flex-direction: column;
            text-align: center;
            padding: 32px 24px;
            justify-content: center;

            @media screen and (min-width: 992px) {
                min-height: 266px;
            }

            & > * {
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &__number {
                font-size: 40px;
                line-height: 1;
                font-weight: bold;
                color: $primarycolor;

                @media screen and (min-width: 1200px) {
                    font-size: 64px;
                }
            }

            &__title {
                font-size: 22px;
                line-height: 1;
                color: $primarycolor;
                font-weight: bold;

                @media screen and (min-width: 1200px) {
                    font-size: 28px;
                }
            }

            &__subtitle {
                line-height: 1;
                font-size: 16px;

                @media screen and (min-width: 1200px) {
                    font-size: 18px;
                }
            }
        }
    }
}

.ourcustomersatisfaction {
    padding-bottom: 64px;
    /* stylelint-disable */
    scroll-margin-top: 300px;
    /* stylelint-enable */

    @media screen and (min-width: 992px) {
        padding-bottom: 96px;
    }

    &__container {
        max-width: 1310px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        column-gap: 87px;
        row-gap: 30px;
        margin-bottom: 46px;

        @media screen and (min-width: 992px) {
            flex-direction: row;
        }

        &__content {
            flex: 1;
            align-self: center;
            min-width: 50%;

            &__title {
                font-size: 30px;
                margin-bottom: 22px;

                @media screen and (min-width: 1200px) {
                    font-size: 42px;
                }
            }

            &__text {
                font-size: 16px;
                margin-bottom: 22px;

                @media screen and (min-width: 1200px) {
                    font-size: 18px;
                }

                :last-child {
                    margin-bottom: 0;
                }
            }

            &__grade {
                font-size: 40px;
                font-weight: bold;
                line-height: 1;
                color: $primarycolor;

                @media screen and (min-width: 1200px) {
                    font-size: 72px;
                }
            }
        }

        &__holder {
            position: relative;
            max-width: 500px;
            width: 100%;
            margin: 0 auto;
            padding-right: 65px;

            &__image {
                border-radius: 100%;
                overflow: hidden;
                width: 100%;
            }

            &__overlay {
                position: absolute;
                top: 26px;
                right: 0;
                z-index: 1;
            }
        }
    }

    &__slider {
        position: relative;

        &__reviews {
            max-width: 1463px;
            margin: 0 33px;
            position: initial;
            border-radius: 15px;
            overflow: hidden;

            @media screen and (min-width: 992px) {
                margin: 0 63px;
            }

            .slick-list {
                margin: 0 -15px;
            }

            .slick-arrow {
                display: flex;
                width: 27px;
                align-items: center;
                top: 50%;
                transform: translateY(-50%);
            }

            .slick-next {
                right: 0;
                justify-content: flex-end;
            }

            .slick-prev {
                left: 0;
            }

            .slick-track {
                display: flex;
            }

            .slick-slide {
                margin: 0 15px;
                height: inherit;
                display: flex;
                justify-content: center;
                align-items: center;

                div {
                    height: 100%;
                }
            }

            &__content {
                background: #f9fafb;
                border-radius: 15px;
                padding: 24px 31px;
                color: $primarycolor;
                font-size: 20px;
                font-style: italic;
                line-height: 32px;
                display: flex;
                flex-direction: column;
                flex: 1;

                @media screen and (min-width: 992px) {
                    padding: 42px 62px;
                    font-size: 24px;
                    line-height: 36px;
                }
            }
        }
    }
}

.ourheros {
    background: $secondarycolor;
    color: white;
    border-radius: 50px;
    padding: 64px 32px;
    position: relative;
    z-index: 1;
    /* stylelint-disable */
    scroll-margin-top: 300px;
    /* stylelint-enable */

    @media screen and (min-width: 992px) {
        padding: 96px;
    }

    @media screen and (min-width: 1600px) {
        padding: 96px 134px;
    }

    &__title {
        text-align: center;
        color: white;
        font-size: 30px;
        margin-bottom: 19px;

        @media screen and (min-width: 1200px) {
            font-size: 42px;
        }
    }

    &__content {
        text-align: center;
        max-width: 906px;
        margin: 0 auto;
        font-size: 16px;

        @media screen and (min-width: 1200px) {
            font-size: 18px;
        }
    }

    &__numbers {
        display: flex;
        row-gap: 10px;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        margin: 13px 0 43px 0;
        column-gap: 40px;

        @media screen and (min-width: 768px) {
            flex-direction: row;
            font-size: 22px;
        }

        @media screen and (min-width: 1600px) {
            column-gap: 80px;
        }

        &__number {
            display: inline-flex;
            align-items: center;
            column-gap: 10px;
            min-width: max-content;

            span {
                font-weight: bold;
                font-size: 24px;
                line-height: 1;

                @media screen and (min-width: 768px) {
                    font-size: 40px;
                }
            }
        }
    }

    &__items {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        position: relative;

        @media screen and (min-width: 640px) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @media screen and (min-width: 992px) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        @media screen and (min-width: 1600px) {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }

        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            border: $secondarycolor solid 1px;
            pointer-events: none;
            z-index: 1;
        }
    }

    .ourhero {
        display: flex;
        column-gap: 16px;
        align-items: center;
        padding: 24px 0;
        border: solid 0.5px transparent;
        transition: 0.3s ease-in-out border;
        z-index: 1;

        &.animated {
            border-color: #36638a;
            z-index: -1;
        }

        @media screen and (min-width: 640px) {
            padding: 37px 16px;
            column-gap: 0;
        }

        &__media {
            max-width: 64px;
            max-height: 64px;
            min-width: 64px;
            min-height: 64px;
            display: flex;
            justify-content: center;
            padding: 12px 0;

            @media screen and (min-width: 640px) {
                max-width: 102px;
                max-height: 102px;
                min-width: 102px;
                min-height: 102px;
            }

            img {
                filter: invert(100%) sepia(0%) saturate(7499%) hue-rotate(183deg) brightness(99%) contrast(97%);
            }
        }

        &:not(.highlighted) {
            flex-direction: row;

            @media screen and (min-width: 640px) {
                flex-direction: column;
            }

            .ourhero__media {
                border: solid 1px white;
                border-radius: 999px;
            }

            .ourhero__content {
                text-align: left;

                @media screen and (min-width: 640px) {
                    text-align: center;
                }

                &__title {
                    justify-content: start;

                    @media screen and (min-width: 640px) {
                        justify-content: center;
                    }
                }
            }
        }

        &.highlighted {
            flex-direction: column;
            background: #36638a;

            .ourhero__media {
                img {
                    filter: invert(63%) sepia(96%) saturate(412%) hue-rotate(16deg) brightness(105%) contrast(108%);
                }
            }

            .ourhero__content {
                text-align: center;
            }
        }

        &__content {
            &__title {
                display: flex;
                align-items: baseline;
                justify-content: center;
                column-gap: 8px;
                margin-bottom: 8px;
                font-size: 26px;

                @media screen and (min-width: 640px) {
                    margin-top: 30px;
                    margin-bottom: 16px;
                    justify-content: center;
                }

                span {
                    font-size: 24px;
                    font-weight: bold;
                    line-height: 1;

                    @media screen and (min-width: 640px) {
                        font-size: 40px;
                    }

                    @media screen and (min-width: 1200px) {
                        font-size: 72px;
                    }
                }

                * {
                    color: white;
                }
            }

            &__text {
                font-size: 16px;

                :last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
