.faq__items {
    .accordion__outer {
        margin-top: 40px;
    }

    .accordion {
        .card-header {
            a {
                color: $body-font-color;
            }
        }
    }
}

.faq__title {
    margin-bottom: 20px;
}

@media screen and (max-width: 1199px) {

}

@media screen and (max-width: 991px) {

}

@media screen and (max-width: 767px) {
    .faq__title {
        font-size: 18px;
        margin-bottom: 5px;
    }
}

@media screen and (max-width: 575px) {

}
