/** Import Bootstrap functions */
@import "~bootstrap/scss/functions";

$slick-loader-path: "~slick-carousel/slick/";
$slick-font-path: "~slick-carousel/slick/fonts/";

$primarycolor: #50af47;
$secondarycolor: #64c2c7;

$font-primary: 'Poppins', sans-serif;
$font-secondary: '';
$body-font-size: 22px;
$body-font-weight: 300;
$body-line-height: 1.7;
//$body-font-color: #4e4e4e;
$body-font-color: #464646;

$header-font-family: 'Poppins', sans-serif;
$header-color: $primarycolor;
$header-font-weight: bold;

// Headings: h1,h2,h3,h4,h5,h6
$headings-margin-bottom: 20px;
$headings-font-family: $font-primary;
$headings-font-weight: 600;
$headings-line-height: 1.3;
$headings-color: $primarycolor;

// Header:
$header-height: 130px;

$border-radius: 10px;
$block-radius: 20px;

$theme-colors: (
    primary: $primarycolor
);

/** Bootstrap navbar fix (https://git.io/fADqW) */
$navbar-dark-toggler-icon-bg: none;
$navbar-light-toggler-icon-bg: none;

//Bootstrap
$grid-gutter-width: 40px !default;

$enable-responsive-font-sizes: true;
$h1-font-size: 36px;
$h2-font-size: 32px;
$h3-font-size: 27px;
$h4-font-size: 22px;
$h5-font-size: 16px;
