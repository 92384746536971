.archive__content--referentie {
    text-align: center;
    margin-bottom: 0;
    line-height: 1.6;

    h1 {
        margin-bottom: 18px;
    }
}

.reference__overview {
    .block__content {
        text-align: center;
        justify-content: center;
        align-items: center;
        padding-top: 0;
    }

    .block {
        border: none;
        box-shadow: none;
    }

    .block__img__inner {
        display: flex;
        align-items: center;
        justify-content: center;
        //padding: 40px 25px;

        img {
            width: auto;
            max-height: 117px;
        }
    }
}

.reference__person {
    color: #505050;
    font-size: 30px;
    font-weight: 600;
    margin: -20px 0 20px;
    display: block;
}

.reference__singleimg {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 1199px) {

}

@media screen and (max-width: 991px) {

}

@media screen and (max-width: 767px) {
    .archive__content--referentie {
        margin-bottom: 0;
    }
}

@media screen and (max-width: 575px) {

}
