@media screen and (max-width: 1199px) {
    .circle__top--front {
        svg {
            width: 110px;
            height: 346px;
        }
    }

    .wpgmp_map_container .location__block {
        width: 100%;
        margin-right: 0;
    }

    .parent__dropdown__list {
        right: 10px;
        width: calc(100% - 10px);
    }

    .parent__dropdown__item {
        padding: 8px 14px;
        font-size: 17px;
    }

    .banner--sub {
        .banner__content {
            top: -37px;
        }
    }

    .banner__content__inner {
        padding-right: 80px !important;
        padding-left: 80px !important;
    }

    .banner__title {
        font-size: 30px;
    }

    .banner__text {
        font-size: 21px;
    }
}
