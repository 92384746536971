$revealDistance: 80px;

[data-animation-section] {
    [data-animation-reveal-type="scale"] {
        transform: scale(1.1);
        opacity: 0;

        &[data-animation-reveal-from="small"] {
            transform: scale(0.9);
            opacity: 0;
        }

        &[data-animation-reveal-from="large"] {
            transform: scale(1.1);
            opacity: 0;
        }
    }

    [data-animation-reveal-type="blur"] {
        transform: scale(1.1);
        opacity: 0;
        filter: blur(16px);

        &[data-animation-reveal-from="small"] {
            transform: scale(0.9);
            opacity: 0;
            filter: blur(16px);
        }

        &[data-animation-reveal-from="large"] {
            transform: scale(1.1);
            filter: blur(16px);
        }
    }

    [data-animation-reveal-type="translate"] {
        opacity: 0;
        transform: translate(0, $revealDistance);

        &[data-animation-reveal-from="top"] {
            transform: translate(0, -#{$revealDistance});
        }

        &[data-animation-reveal-from="bottom"] {
            transform: translate(0, #{$revealDistance});
        }

        &[data-animation-reveal-from="left"] {
            transform: translate(-#{$revealDistance}, 0);
        }

        &[data-animation-reveal-from="right"] {
            transform: translate(#{$revealDistance}, 0);
        }
    }
}
