@import "~bootstrap/scss/bootstrap";

.container-fluid {
    .container-fluid {
        padding: 0;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1615px;
    }

    .container-fluid {
        max-width: 1615px;
    }

    .container-fluid--small {
        max-width: 1575px;
    }
}
