.search-form {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 795px;
    //margin-bottom: 53px;

    label {
        flex: 1;
        margin-bottom: 0;
    }

    .search-submit {
        @extend .btn--primary;

        border: none;
        padding: 8px 65px;
        font-size: 22px;
        outline: none;
        height: 75px;
        color: #fff;
        font-weight: 600;
        text-transform: uppercase;
        border-radius: 10px;
        margin-left: -6px;

        &:hover,
        &:active,
        &:focus {
            box-shadow: none;
        }
    }
}

.search-field {
    margin: 0;
    padding: 8px 30px;
    width: 100%;
    border: none;
    border-right: 0;
    border-radius: 10px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    outline: none;
    resize: none;
    color: $body-font-color;
    height: 75px;
    background-color: #f2f2f2;
    font-weight: 300;
}

.search__content {
    position: relative;
}

.search__word {
    font-size: 27px;
    color: $primarycolor;
    display: block;

    b {
        font-weight: 700;
    }
}

.search__word__amount {
    display: block;
    margin: 30px 0 30px;
}

.search__amount {
    display: block;
    font-size: 16px;
    color: $body-font-color;
    font-weight: 700;
}

.search__result__row {
    position: relative;
    display: block;
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid #ececec;

    &:hover {
        text-decoration: none;

        .search__result__title {
            text-decoration: underline;
        }
    }
}

.search__result__title {
    color: #4787af;
    margin-bottom: 3px;
    display: block;
    font-size: 22px;
    font-weight: 400;
}

.search__result__path {
    display: block;
    margin-bottom: 10px;
    font-size: 16px;
    color: $primarycolor;
    font-weight: 300;

    svg {
        position: relative;
        top: -1px;
        height: 14px;
        width: 5px;
        margin: 0 10px;
        fill: $secondarycolor;
        opacity: 0.9;
    }
}

.search__result__text {
    display: block;
    color: $body-font-color;
}

@media screen and (max-width: 1199px) {

}

@media screen and (max-width: 991px) {
    .search-form {
        .search-submit {
            font-size: 15px;
            height: 55px;
            padding: 8px 40px;
        }

        .search-field {
            height: 55px;
        }
    }

    .search__result__path {
        font-size: 14px;
    }

    .search__result__title {
        font-size: 17px;
    }

    .search__word {
        font-size: 20px;
    }

    .search__amount {
        font-size: 14px;
    }
}

@media screen and (max-width: 767px) {
    .search-form {
        .search-submit {
            padding: 8px 30px;
        }
    }

    .search-field {
        padding: 8px 15px;
    }

    .search__word {
        font-size: 18px;
    }
}
