.sidebar .block {
    height: auto;
    padding: 38px;

    &:hover {
        box-shadow: none;
    }
}

.sidebar__links {
    margin-bottom: 90px;
}

.sidebar__contact__margin {
    margin-top: 24px;
}

[class*="sidebar__links__"] {
    display: flex;
    align-items: center;
    height: 67px;
    margin-bottom: 16px;
    padding: 0 20px 0 80px;
    font-size: 21px;
    font-weight: 500;
    line-height: 19px;
    color: $white;
    border-radius: 5px;

    &:hover,
    &:focus {
        color: #ededed;
        opacity: 0.8;
    }
}

.sidebar__links__tickets {
    background: #333 url(../../svg/ticket-alt-solid.svg) no-repeat 30px center;
    background-size: 23px 23px;
}

.sidebar__links__parent {
    background: #cdca24 url(../../svg/user.svg) no-repeat 30px center;
    background-size: 23px 23px;
}

.sidebar__links__register {
    background: $secondarycolor url(../../svg/pencil.svg) no-repeat 30px center;
    background-size: 23px 23px;
}

.sidebar__links__calculation {
    background: #36638a url(../../svg/calculator.svg) no-repeat 30px center;
    background-size: 18px 21px;
}

.sidebar__links__tour {
    background: $primarycolor url(../../svg/arrow.svg) no-repeat 30px center;
    background-size: 24px 24px;
}

.sidebar .block__title {
    margin-bottom: 10px;
    font-size: 22px;
    font-weight: 600;
    color: $primarycolor;
}

.sidebar__contact {
    line-height: 1.4;

    label {
        font-weight: 600;
        color: $primarycolor;
    }

    span {
        display: block;
    }

    a {
        text-decoration: underline;
        color: $secondarycolor;
    }

    .sidebar__contact__link {
        a {
            text-decoration: none;
            color: $body-font-color;

            &:hover {
                text-decoration: underline;
                color: $secondarycolor;
            }
        }
    }
}

.usp__list {
    display: block;
    margin-top: 10px;
}

.usp__item {
    position: relative;
    display: block;
    padding-left: 30px;
    line-height: 1;

    &:not(:last-child) {
        margin-bottom: 19px;
    }

    svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 17px;
        height: 17px;
        margin-right: 10px;
        color: $primarycolor;
    }
}

@media screen and (max-width: 1599px) {
    .sidebar {
        padding-left: 50px;
    }

    .sidebar__contact {
        font-size: 17px;
    }

    .sidebar .block {
        padding: 25px;
    }

    .sidebar .block__title {
        font-size: 20px;
    }

    [class*=sidebar__links__] {
        height: 60px;
        margin-bottom: 10px;
        padding: 0 20px 0 60px;
        font-size: 19px;
        background-position-x: 20px;
        background-size: 20px 20px;
    }
}

@media screen and (max-width: 1199px) {
    .sidebar {
        padding-left: 40px;
    }

    .sidebar__contact {
        font-size: 16px;
    }

    .sidebar .block__title {
        font-size: 18px;
    }

    [class*=sidebar__links__] {
        height: 50px;
        padding: 0 20px 0 50px;
        font-size: 16px;
        background-size: 16px 16px;
    }

    .usp__item {
        svg {
            top: 6px;
        }
    }

    .page-wrap--location {
        .usp__item {
            svg {
                top: 1px;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .sidebar {
        margin-top: 30px;
        padding-left: 20px;
    }

    .sidebar__contact {
        font-size: 15px;
    }

    .sidebar .block__title {
        font-size: 17px;
    }

    .sidebar__links {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 30px;
    }

    [class*=sidebar__links__] {
        width: calc(50% - 5px);

        &:nth-child(odd) {
            margin-right: 10px;
        }
    }

    .usp__item {
        padding-left: 25px;

        svg {
            top: 4px;
            width: 15px;
            height: 15px;
        }
    }
}

@media screen and (max-width: 767px) {
    .sidebar--location {
        .block {
            margin-bottom: 25px;

            &:last-of-type {
                margin-top: 0;
            }
        }
    }
}

@media screen and (max-width: 575px) {
    .sidebar__contact {
        font-size: 14px;
    }

    .sidebar .block__title {
        font-size: 16px;
    }

    [class*=sidebar__links__] {
        width: 100%;
        min-width: 100%;
        margin: 0 0 10px;
    }
}
