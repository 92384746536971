.page-wrap--landing {
    .archive__list {
        margin-top: 44px;

        .section__title {
            color: $primarycolor;
            text-align: left;
        }

        .vacancy__item a {
            margin-top: 35px;
            text-decoration: none !important;
        }
    }
}

.landing-bso {
    &.space-right {
        padding-right: 40px;
    }

    a:not(.btn) {
        color: $secondarycolor;
    }
}

.landing-bso .section__title {
    font-weight: 600;
    text-align: left;
    color: $primarycolor;
}

.landing-bso .section__desc {
    text-align: left;
}

.quote__block__worker {
    padding-top: 150px;

    .section__links {
        margin-top: 15px;
        color: $primarycolor;
        font-weight: 500;
        font-size: 19px;

        &__whatsapp {
            color: $primarycolor !important;
            text-decoration: none !important;

            svg {
                width: 18px;
                height: 21px;
                vertical-align: -3px;
                margin-right: 4px;
            }

            &:hover {
                color: #464646 !important;
            }
        }

        &__email {
            color: $primarycolor !important;

            &:hover {
                color: #464646 !important;
            }
        }
    }
}

.section__leftcol {
    span {
        display: block;
    }

    .section__content {
        span {
            display: inline;
            color: $secondarycolor;
        }
    }

    &:hover {
        .section__content {
            span {
                color: $body-font-color;
            }
        }
    }
}

.section__url {
    margin-top: 25px;
}

.landing-detail__sidebar {
    .block__list {
        padding: 6px 0 0;
    }

    .block__item {
        margin: 0 0 20px 35px;
        font-size: 19px;
        line-height: 1.5;

        &::before {
            left: -29px;
            width: 18px;
            content: "";
            background: url('../../images/check-solid-green.svg') no-repeat;
            background-size: 15px 15px;
        }

        &:first-child {
            margin-top: 0;
        }
    }
}

.landing-detail__accordions {
    &__content {
        padding-top: 22px;
        line-height: 36px;
    }

    &__btn {
        display: block;
        color: $primarycolor !important;
        text-decoration: none !important;
        font-weight: 500;
        position: relative;
        padding-top: 22px;
        padding-bottom: 22px;
        padding-right: 70px;
        border-bottom: solid 1px #efefef;

        &::after {
            content: "";
            background: url('../../svg/angle-up-solid-blue.svg') no-repeat center;
            background-size: 17px;
            width: 54px;
            height: 39px;
            display: inline-block;
            border: solid 1px #efefef;
            border-radius: 10px;
            position: absolute;
            top: 50%;
            right: 0;
            margin-top: -19.5px;
            transition: 0.3s ease-in-out background-color;
        }

        &:hover {
            &::after {
                content: "";
                background-color: #efefef !important;
            }
        }

        &.collapsed {
            &::after {
                background: url('../../svg/angle-down-solid-blue.svg') no-repeat center;
                background-size: 17px;
            }
        }
    }
}

.landing-detail__sidebar .section {
    flex-direction: column;
    padding: 0;
    margin-top: 66px;

    &__image {
        width: 230px;
        min-width: 230px;
        height: 230px;
        margin-bottom: 28px;

        &::before {
            top: -25px;
            right: -25px;
            width: 80px;
            height: 100px;
        }
    }

    &__heading {
        font-size: 25px;
        margin-bottom: 0;
        line-height: 33px;
    }

    &__date {
        line-height: 33px;
    }

    &__content {
        margin-top: 0;
        line-height: 33px;
        font-size: 20px;

        p {
            margin-bottom: 0;
        }
    }

    &__links {
        margin-top: 15px;
        color: $primarycolor;
        font-weight: 500;
        font-size: 19px;

        &__whatsapp {
            color: $primarycolor !important;
            text-decoration: none !important;

            svg {
                width: 18px;
                height: 21px;
                vertical-align: -3px;
                margin-right: 4px;
            }

            &:hover {
                color: #464646 !important;
            }
        }

        &__email {
            color: $primarycolor !important;

            &:hover {
                color: #464646 !important;
            }
        }
    }

    &__leftcol {
        padding: 0;
    }
}

@media screen and (max-width: 1599px) {
    .section__title--sm {
        font-size: 30px;
    }
}

@media screen and (max-width: 1199px) {
    .landing-detail__sidebar {
        .block__item {
            margin: 0 0 8px 30px;
            font-size: 17px;

            &::before {
            }
        }
    }

    .quote__block__worker {
        padding-top: 65px;
    }

    .section__title--sm {
        font-size: 25px;
    }
}

@media screen and (min-width: 991px) {
    .landing-detail__sidebar {
        .block {
            margin-top: 0;
        }
    }
}

@media screen and (max-width: 991px) {
    .page-wrap--landing .archive__list .vacancy__item a {
        margin-top: 24px;
        margin-bottom: 24px;
    }

    .landing-detail__sidebar .section {
        margin-top: 33px;

        &__heading {
            font-size: 17px;
        }

        &__links__whatsapp svg {
            vertical-align: -5px;
        }

        &__content,
        &__links {
            font-size: 13px;
            line-height: 20px;
            margin-top: initial;
            margin-bottom: 7px;
        }
    }

    .landing-bso {
        &.space-right {
            padding-right: 20px;
        }
    }

    .section__title--sm {
        font-size: 22px;
    }

    .quote__block__worker {
        .section__content {
            margin-top: 0;

            p {
                margin-bottom: 7px;
                font-size: 13px;
            }
        }

        .section__links {
            margin-top: 0;
            font-size: 13px;

            &__whatsapp svg {
                vertical-align: -5px;
            }
        }
    }

    .landing-detail__sidebar {
        .block__item {
            font-size: 16px;

            &::before {
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .quote__block__worker {
        padding-top: 40px;
        padding-bottom: 40px;
        padding-right: 10px;
        padding-left: 10px;
    }

    .landing-detail__sidebar {
        .block__item {
            font-size: 14px;

            &::before {
                background-size: 11px 11px;
            }
        }
    }
}

@media screen and (max-width: 605px) {
}

@media screen and (max-width: 575px) {
    .landing-detail__sidebar .block__item {
        padding-left: 0;
    }
}

@media screen and (max-width: 359px) {
}
