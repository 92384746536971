@media screen and (max-width: 991px) {
    .front__location {
        padding: 37px 40px 23px 40px;
    }

    .front__location__title {
        svg {
            width: 50px;
            height: 50px;
        }
    }

    .front__location__title__txt {
        .title {
            font-size: 30px;
        }
    }

    .front__location__search {
        margin-top: 40px;
    }

    .circle__top--front {
        svg {
            width: 50px;
            height: 156px;
        }
    }

    .vacancy__filter form > ul {
        gap: 0 20px;
    }

    .vacancy__filter .filter__dropdown > ul {
        min-width: 250px;
    }

    .banner--sub {
        .banner__content {
            top: auto;
        }
    }

    .banner__item {
        .banner__content {
            display: none;
        }
    }

    .banner__info {
        .banner__content {
            display: block;
        }
    }

    .banner__content {
        position: relative;
        z-index: 1;
        top: auto;
        left: auto;
        margin-bottom: 20px;

        .container-fluid {
            max-width: 90%;
        }
    }

    .page-wrap__inner {
        .banner__content {
            .container-fluid {
                max-width: 100%;
            }
        }
    }

    .banner__content__inner {
        margin-top: -50px;
        padding: 25px 35px !important;
        text-align: center;
        border-radius: 10px;
        background-color: $secondarycolor;
    }

    .banner__title {
        font-size: 26px;
    }

    .banner__text {
        font-size: 16px;
        font-weight: 300;
    }

    .parent__dropdown__list {
        top: calc(100% + 10px);
        width: auto;
        border-radius: 7px;
    }

    .parent__dropdown__item {
        font-size: 15px;
    }
}
