// Page
.row--page {
    margin-bottom: 35px;

    .col {
        > p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.row--gallery {
    .col {
        &:nth-child(n+5) {
            margin-top: 30px;
        }
    }
}

.page__slider {
    position: relative;

    .slick-dots {
        bottom: -43px;
    }

    .page__slider__item {
        video {
            /* stylelint-disable */
            aspect-ratio: 415/242;
            /* stylelint-enable */
            object-fit: cover;
        }

        a {
            position: relative;
            border-radius: 10px;
            overflow: hidden;
            display: flex;

            &:focus {
                outline: none;
            }
        }

        &:focus {
            outline: none;
        }

        .page__slider__item__video__icon {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            height: 100%;
            width: 100%;
            align-items: center;
            justify-content: center;
            background: rgba(0, 0, 0, 0.25);
        }

        .page__slider__item__video__svg {
            svg {
                width: 64px;
                height: 64px;
                fill: #fff;
            }
        }
    }

    &.slick-dotted {
        &.slick-slider {
            margin-bottom: 40px;
        }
    }

    .slick-arrow {
        position: absolute;
        font-size: 36px;
        text-align: center;
        top: 50%;
        cursor: pointer;
        width: 50px;
        height: 50px;

        &.fa-angle-left {
            left: -40px;
        }

        &.fa-angle-right {
            right: -40px;
        }
    }
}

.row--columns {
    img {
        border-radius: 20px;
    }
}

.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
    }
}

.cta-blocks {
    margin: 48px 0 0 0;

    h2 {
        font-weight: 400;
        color: $secondarycolor;
    }
}

.cta-blocks__collection {
    justify-items: stretch;
}

.cta-block {
    &__content {
        position: relative;
        padding-top: 50px;
    }

    &__icon {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        border-radius: 30px;
        background: $primarycolor;
        color: #fff;

        svg {
            width: 36px;
            height: 36px;
        }
    }

    &__title {
        margin-bottom: 16px;
        font-weight: 600;
        color: $primarycolor;

        &:has(+ .cta-block__more) {
            margin-bottom: 32px;
        }
    }

    &__text {
        color: $body-font-color;
        margin-bottom: 16px;
    }

    &__more {
        margin-top: auto;
        color: $body-font-color;
    }
}

@media screen and (max-width: 1275px) {
    .row--slider {
        padding: 0 40px;
    }
}

@media screen and (max-width: 991px) {
    .row--gallery {
        .col {
            &:nth-child(n+4) {
                margin-top: 30px;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .row--columns {
        > .col {
            margin-bottom: 20px;
        }
    }

    .row--gallery {
        .col {
            &:nth-child(n+3) {
                margin-top: 30px;
            }
        }
    }

    .cta-blocks__collection {
        margin-top: 32px;
    }
}

@media screen and (max-width: 380px) {
    .row--gallery {
        > .col {
            flex: 0 0 100%;
            max-width: 100%;
            margin-top: 30px;

            &:first-child {
                margin-top: 0;
            }
        }
    }
}
