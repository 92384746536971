@media screen and (max-width: 575px) {
    .banner__content__inner {
        margin-top: -80px;
    }

    .banner__content .container-fluid {
        max-width: 96%;
    }

    .vacancy__filter .sf-meta-range-slider {
        margin-left: -10px;
        width: 95%;
    }
}
