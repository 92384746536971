body {
    overflow-x: hidden;
    font-family: $font-primary;
    font-size: $body-font-size;
    font-weight: $body-font-weight;
    line-height: $body-line-height;
    color: $body-font-color;

    &.archive-vacatures-data {
        .cta {
            padding-top: 100px;
            padding-bottom: 30px;
        }
    }
}

.main {
    display: flex;
    flex-direction: column;
}

.gform_submission_error {
    color: #ef5350;
    font-size: 15px;
}

.scroll__top {
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 99;
    border: none;
    outline: none;
    background-color: #50af47;
    color: white;
    cursor: pointer;
    padding: 15px;
    border-radius: 10px;
    font-size: 18px;
    transition: opacity 0.3s ease;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;

    svg {
        width: 26px;
        height: 26px;
    }

    &.active {
        visibility: visible;
        opacity: 100;
    }

    &:focus {
        outline: none;
    }

    &:hover {
        background-color: #5eba55;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $header-font-family;
    font-weight: $header-font-weight;
    color: $header-color;
}

h1,
h2 {
    margin-bottom: 30px;
}

h3,
h4 {
    margin-bottom: 20px;
}

h5,
h6 {
    margin-bottom: 15px;
}

.banner__info {
    position: relative;

    .banner__content {
        display: none;
    }
}

.vacatures__scroll {
    position: absolute;
    left: 0;
    top: -80px;
    pointer-events: none;
}

.svg-inline--fa {
    width: 27px;
    height: auto;
}

.wpgmp_filter_wrappers {
    ::placeholder {
        font-size: 22px !important;
    }
}

a {
    transition: all 300ms ease-in-out;

    &:hover,
    &:focus {
        text-decoration: none;
        color: $primarycolor;
    }
}

p {
    margin-bottom: 30px;
}

b,
strong {
    font-weight: 700;
}

.acf-map {
    width: 100%;
    height: 500px;
    margin: 0;
}

.acf-map img {
    max-width: inherit !important;
}

.slick-slider {
    transition: all 0.3s ease;
}

.page-wrap__inner--news {
    .single__main {
        hr {
            background-color: $primarycolor;
        }
    }
}

//.archive__content {
//    padding: 80px 0 40px;
//}
//
//.archive__list {
//    padding: 0 0 40px;
//}

.archive__list {
    > .row {
        margin-right: -25px;
        margin-left: -25px;

        > [class*="col-"] {
            padding-right: 25px;
            padding-left: 25px;
        }
    }
}

.archive__content {
    margin-bottom: 15px;
}

.slider__content {
    position: relative;

    a {
        position: relative;
        float: left;
    }
}

.slider__wrapper {
    overflow: hidden;
}

.slider__wrapper__top {
    position: relative;
}

.slider__play {
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    pointer-events: none;

    svg {
        width: 50px;
        height: 50px;
        color: #fff;
    }
}

.slider__top__prev,
.slider__top__next {
    position: absolute;
    z-index: 9;
    top: 50%;
    margin-top: -40px;

    svg {
        width: 80px;
        height: 80px;
        cursor: pointer;
        color: rgba(#fff, 0.7);
    }
}

.slider__top__prev {
    left: 10px;
}

.slider__top__next {
    right: 10px;
}

.slick-slide {
    cursor: pointer;
    outline: none;

    &:hover,
    &:focus,
    &.active {
        outline: none;
    }

    &.slick-current {
        cursor: default;
    }

    a {
        &:focus {
            outline: none !important;
        }
    }

    &:focus {
        outline: none !important;
    }

    img {
        border-radius: $border-radius;
        outline: none !important;

        &:focus {
            outline: none !important;
        }
    }
}

.slider__nav {
    margin: 10px -5px 0;
    padding: 0 1px;
}

.slider__nav__item {
    position: relative;
    padding: 0 4px;

    .slider__play {
        svg {
            width: 25px;
            height: 25px;
        }
    }
}

.gform_body,
.apply {
    .hidden_label {
        height: 0;
        margin: 0;
        opacity: 0;
    }
}

.grecaptcha-badge {
    display: none;
}

.block__readmore {
    font-size: 17px;
    font-weight: 300;
    color: $secondarycolor;
}

.block {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-top: 50px;
    border: 1px solid #d5d5d5;
    border-radius: $block-radius;
    background-color: $white;

    &:hover {
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

        img {
            transform: scale(1.05);
        }

        .block__readmore {
            color: $body-font-color;
            background-color: transparent !important;
        }
    }
}

.block__img__inner {
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;

    img {
        width: 100%;
        max-width: 100%;
        transition: all 0.3s ease;
    }
}

.pagination {
    flex-wrap: wrap;
    justify-content: center;
    margin: 50px 0 0 0;

    .page-numbers {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 67px;
        height: 64px;
        font-size: 25px;
        font-weight: 400;
        border: 0;
        background-color: transparent;
        border-radius: 50%;

        &.current {
            color: #fff;
            background-color: $primarycolor;
        }
    }

    button,
    a {
        &.page-numbers {
            color: $body-font-color;

            &:hover {
                color: $primarycolor;
            }

            &:focus {
                outline: 0;
            }

            &.current {
                &:hover {
                    color: $white;
                }
            }
        }
    }
}

.a2a_kit {
    margin-left: -8px;
}

.block__cat {
    margin-bottom: 10px;
    font-family: $font-primary;
    font-size: 17px;
    font-weight: 600;
}

.block__cat--blogs {
    color: #36638a;
}

.block__content {
    display: flex;
    align-items: flex-start;
    flex: 1;
    flex-direction: column;
    padding: 35px 45px 32px 45px;
    color: $body-font-color;
    background-color: $white;

    .btn {
        position: relative;
        display: inline-block;
        margin-top: auto;
        transition: all 0.3s;
        font-size: 13px;

        svg {
            width: 7px;
            height: 18px;
            margin-top: 2px;
            margin-left: 10px;
            transition: all 0.3s ease;
        }
    }
}

.block__title {
    display: block;
    font-family: $font-primary;
    font-size: 19px;
    font-weight: 300;
    line-height: 1.5;
    color: $secondarycolor;
}

.block__news .block__title {
    height: auto !important;
}

.block__subtitle {
    margin-top: 5px;
    margin-bottom: 20px;
    font-weight: 600;
}

.block__phone,
.block__email {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 34px;
    word-break: break-all;

    svg {
        position: absolute;
        top: 4px;
        left: 0;
        width: 16px;
        height: 16px;
        color: $primarycolor;
        fill: $primarycolor;
    }
}

.block__phone {
    margin-bottom: 4px;
}

.block__no__img {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 150px;
    background-color: #f9f9f9;
}

.block__date,
.block__shortinfo {
    display: block;
    margin-top: 5px;
    font-size: 14px;
    font-weight: 700;
    color: #505050;

    svg {
        width: 12px;
        height: 13px;
        margin: -4px 8px 0 0;
        color: #505050;
    }
}

.block__summary {
    display: block;
    height: 100%;
    margin: 15px 0 15px;
    font-size: 17px;
    font-weight: 300;
    line-height: 1.5;
    color: $body-font-color;
}

.vaccancy__toggle {
    margin-bottom: 0 !important;
}

.page-wrap--vaccancy {
    .section:first-of-type {
        padding-top: 75px;
    }
}

.searchandfilter ul {
    padding-left: 33px;
}

.block__list {
    padding: 19px 0 0;
}

.archive__content__filter {
    margin-top: 46px;
}

.archive__content__filter__inner {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;

    .sf-field-category {
        position: relative;

        &::after {
            position: absolute;
            top: 50%;
            right: 15px;
            width: 28px;
            height: 28px;
            content: '';
            transform: translateY(-50%);
            pointer-events: none;
            background: url('../../svg/angle-down-regular-greenish.svg') no-repeat;
        }
    }

    .sf-input-select {
        padding: 20px 65px 20px 35px;
        font-size: 22px;
        font-weight: 300;
        border: none;
        border-radius: 10px;
        outline: none;
        background-color: #f2f2f2;
        box-shadow: none;
        -webkit-appearance: none;
    }

    .sf-field-tag {
        display: none;
    }

    select::-ms-expand {
        display: none;
        cursor: pointer;
    }
}

.archive__content__filter__label {
    font-size: 18px;
    font-weight: 700;
    color: $body-font-color;
}

.tag__search {
    margin-top: 0;

    .archive__content__filter__label,
    form {
        display: none;
    }
}

.block__item {
    position: relative;
    display: block;
    margin: 0 0 8px 20px;
    color: $body-font-color;

    &:last-child {
        margin-bottom: 0;
    }

    &::before {
        position: absolute;
        top: 7px;
        left: -18px;
        width: 6px;
        height: 18px;
        content: "";
        background: url('../../images/angle-right-regular.svg') no-repeat;
        background-size: 6px 16px;
    }
}

.single__content {
    position: relative;
    padding: 50px 0;

    .btn--prev {
        margin-top: 30px;
    }

    h3 {
        margin-top: 30px;
    }
}

.fa-phone {
    transform: rotate(90deg);
}

.accordion {
    margin: auto;
}

.circle__top--front {
    position: absolute;
    top: 50px;
    right: 0;

    svg {
        width: 200px;
        height: 629px;
        margin-top: 0;

        &.cthm {
            display: none;
        }
    }
}

.project__detail h1 {
    margin-bottom: 7px;
}

// Custom Checkbox
.checkmark {
    position: absolute;
    top: 4px;
    left: 0;
    width: 20px;
    height: 20px;
    border: 1px solid #c9c9c9;
    border-radius: 3px;
    background-color: $white;

    &::after {
        position: absolute;
        display: none;
        content: "";
    }

    &::before {
        position: absolute;
        content: "";
    }
}

.checkmarkcontainer {
    position: relative;
    display: flex;
    min-width: 20px;
    min-height: 20px;
    margin: 0;
    padding-left: 30px;
    cursor: pointer;
    user-select: none;
    font-size: 19px;
    color: #64c2c7;

    input {
        position: absolute;
        width: 0;
        height: 0;
        cursor: pointer;
        opacity: 0;
    }

    a {
        font-size: 13px;
        text-decoration: underline;
        color: #9a9a9a;
    }
}

.checkmarkcontainer:hover input ~ .checkmark {
    background-color: #eef9f9;

    &::after {
        display: block;
    }

    &::before {
        display: none;
    }
}

.checkmarkcontainer input:checked ~ .checkmark {
    border-color: $primarycolor;
    background-color: $primarycolor;
}

.checkmarkcontainer .checkmark {
    &::before,
    &::after {
        top: 2px;
        left: 6px;
        width: 6px;
        height: 11px;
        transform: rotate(45deg);
        border: solid $white;
        border-width: 0 2px 2px 0;
    }
}

// Placeholder styling
::-webkit-input-placeholder {
    font-size: 16px !important;
    color: #afafaf !important;
}

:-ms-input-placeholder {
    font-size: 16px !important;
    color: #afafaf !important;
}

::placeholder {
    font-size: 16px !important;
    color: #afafaf !important;
}

// Radio
.radiocontainer {
    position: relative;
    margin-bottom: 0;
    padding-left: 30px;
    cursor: pointer;
    user-select: none;
    line-height: 20px;
}

.input-group .form-check .radiocontainer {
    font-size: 19px;
    font-weight: 300;
}

.radiocontainer input {
    position: absolute;
    cursor: pointer;
    opacity: 0;
}

.radiocontainer:hover input ~ .checkmark {
    background-color: #ccc;
}

.radiocontainer input:checked ~ .checkmark {
    background-color: #2196f3;

    &::after {
        display: block;
    }
}

.radiocontainer .checkmark::after {
    top: 6px;
    left: 10px;
    width: 7px;
    height: 12px;
    transform: rotate(45deg);
    border: solid $white;
    border-width: 0 3px 3px 0;
}

.radiocontainer .radiobtn {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border: 1px solid #c9c9c9;
    border-radius: 50%;
    background-color: $white;

    &::after {
        position: absolute;
        top: 3px;
        left: 7px;
        display: none;
        width: 5px;
        height: 10px;
        content: "";
        transform: rotate(45deg);
        border: solid $white;
        border-width: 0 2px 2px 0;
    }
}

.radiocontainer input:checked ~ .radiobtn {
    border-color: $primarycolor;
    background-color: $primarycolor;

    &::after {
        display: block;
    }
}

.form-check {
    margin: 0 5px 0 0;
}

.form-group {
    margin-bottom: 18px;

    label {
        margin-bottom: 5px;
        font-size: 15px;
        font-weight: 700;
        color: #505050;
    }
}

.page-wrap--default .loc-content {
    p {
        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.form-control {
    height: 52px;
    padding: 0 19px;
    font-size: 18px;

    &:hover,
    &:focus,
    &.active {
        box-shadow: none;
    }
}

textarea.form-control {
    height: 93px;
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: normal;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .block {
        display: block;
        flex: none;
    }

    .block__content {
        display: block;
    }
}

@media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance:none) {
        .wpgmp_map_container {
            width: 100%;

            .btn--zoeklocaties {
                //margin-left: 18px;
            }
        }
    }
}

@media screen and (max-width: 1599px) {
    .form-group {
        margin-bottom: 15px;

        label {
            font-size: 14px;
        }
    }

    .form-control {
        height: 45px;
        padding: 0 15px;
        font-size: 16px;
    }

    .input-group .form-check .radiocontainer,
    .checkmarkcontainer {
        font-size: 17px;
    }

    .block__item {
        font-size: 18px;
    }
}

@media (max-width: 1420px) {
    .archive__content__filter__inner {
        justify-content: flex-start;

        ul {
            padding: 0;
        }
    }

    .archive__content__filter__label {
        padding-right: 40px;
    }
}

@media (max-width: 1199px) {
    .archive__list {
        > .row {
            margin-right: -20px;
            margin-left: -20px;

            > [class*="col-"] {
                padding-right: 20px;
                padding-left: 20px;
            }
        }
    }

    .block {
        margin-top: 40px;
    }

    .block__content {
        padding: 35px;
    }

    body {
        font-size: 15px;
    }

    .block__title {
        font-size: 17px;
    }

    .archive__content__filter {
        margin-top: 25px;
    }

    .archive__content__filter__inner .sf-input-select {
        padding-left: 35px;
        font-size: 17px;
    }

    .slider__top__prev,
    .slider__top__next {
        margin-top: -30px;
    }

    .slider__top__prev svg,
    .slider__top__next svg {
        width: 60px;
        height: 60px;
        cursor: pointer;
        color: rgba(255, 255, 255, 0.7);
    }

    .slider__top__prev {
        left: 10px;
    }

    .slider__top__next {
        right: 10px;
    }

    .block__item {
        font-size: 17px;
    }

    .block__news {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media screen and (max-width: 991px) {
    .block__item {
        font-size: 16px;
    }

    .archive__content {
        margin-bottom: 20px;
    }
}

@media (max-width: 767px) {
    .block__item {
        font-size: 15px;
    }

    .pagination {
        margin: 0 0 0 0;

        .page-numbers {
            width: 47px;
            height: 44px;
            font-size: 18px;
        }
    }

    .block {
        margin-top: 0;
        margin-bottom: 40px;
    }

    .archive__content__filter__label {
        padding-right: 15px;
    }

    .block__content {
        padding: 30px;
    }

    .vaccancy-bso {
        .section__title {
            font-size: 22px;
        }
    }

    .page-wrap--default,
    .page-wrap--location,
    .archive__content__text,
    .page-wrap__inner--news__single {
        h1 {
            margin-bottom: 15px;
        }
    }

    .block__summary {
        margin: 15px 0 15px;
    }

    body {
        font-size: 15px;
    }

    .layer {
        padding: 40px 0;

        &.archive__list {
            padding-top: 0;
        }
    }

    .archive__list {
        .block__news {
            &:first-of-type {
                .block {
                    margin-top: 0;
                }
            }
        }
    }

    .childcare__wrapper {
        padding-bottom: 30px !important;
    }

    .block__date {
        margin-bottom: 0;
    }

    .archive__content {
        margin-bottom: 30px;
    }

    .archive__content__filter {
        margin-top: 20px;
    }

    .tag__search {
        margin-top: 20px;
    }

    .block__news {
        flex: 0 0 100%;
        max-width: 100%;

        .block {
            margin-top: 40px;
            margin-bottom: 0;
        }

        &:last-of-type {
            .block {
                margin-bottom: 30px;
            }
        }
    }

    .page-wrap__vacancy__archive {
        .col-lg-4 {
            &:last-of-type {
                .block {
                    margin-bottom: 0;
                }
            }
        }
    }

    .archive__content__filter__inner {
        .sf-input-select {
            padding: 10px 45px 10px 25px;
            font-size: 17px;
        }

        .sf-field-category {
            &::after {
                right: 10px;
                width: 20px;
                height: 20px;
            }
        }
    }

    .wpgmp_filter_wrappers {
        ::placeholder {
            font-size: 16px !important;
        }
    }
}

@media screen and (max-width: 575px) {
    .input-group .form-check .radiocontainer,
    .checkmarkcontainer,
    .form-group label,
    .form-control {
        font-size: 14px;
    }

    .block__item {
        padding-left: 22px;
        font-size: 14px;

        &::before {
            background-size: 11px 11px;
        }
    }

    .archive__content__filter__inner .sf-input-select {
        font-size: 15px;
    }
}
