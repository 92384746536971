.btn {
    position: relative;
    padding: 15px 32px;
    border: 0;
    border-radius: 6px;
    margin-right: 19px;
    color: $white;
    font-size: 20px;
    font-weight: 600;

    &:hover,
    &:active,
    &:focus {
        box-shadow: none !important;
        color: $white;
    }

    &:last-child {
        margin-right: 0;
    }
}

.btn--primary {
    background-color: $primarycolor;

    &:hover,
    &:active,
    &:focus {
        background-color: lighten($primarycolor, 5) !important;
    }
}

.btn--secondary {
    background-color: $secondarycolor;

    &:hover,
    &:active,
    &:focus {
        background-color: lighten($secondarycolor, 5) !important;
    }
}

.btn--next {
    background-color: $primarycolor;
    padding-right: 38px;
    font-size: 12px;
    padding-top: 13px;
    padding-bottom: 13px;
    line-height: normal;

    &::after {
        content: '';
        position: absolute;
        right: 20px;
        top: 50%;
        width: 6px;
        height: 14px;
        background: url('../../svg/angle-right-regular-white.svg') no-repeat;
        background-size: 5px 15px;
        transform: translateY(-50%);
    }

    &:hover,
    &:active,
    &:focus {
        background-color: lighten($primarycolor, 5) !important;
    }
}

.btn--prev {
    background-color: $primarycolor;
    padding-left: 40px;

    &::before {
        content: '';
        position: absolute;
        left: 17px;
        top: 50%;
        margin-top: -9px;
        width: 7px;
        height: 18px;
        background-image: url('../../svg/angle-left-regular-white.svg');
        background-size: 7px 18px;
    }

    &:hover,
    &:active,
    &:focus {
        background-color: lighten($primarycolor, 5) !important;
    }
}

.btn-danger {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;

    svg {
        width: 15px;
        height: 15px;
        margin-left: 10px;
    }
}

@media screen and (max-width: 1599px) {
    .btn {
        font-size: 18px;
    }
}

@media screen and (max-width: 575px) {
    .btn {
        font-size: 15px;
        padding: 10px 22px;
    }
}
