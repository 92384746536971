.marker__inner {
    display: block;
    padding: 8px 10px 8px 10px;
}

.marker__title {
    display: block;
    margin: 0 0 8px 0;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    color: $primarycolor;
}

.marker__item {
    display: block;
    width: 100%;
    margin-bottom: 5px;
    font-size: 15px;
    text-align: center;

    &.btn {
        margin: 15px 0 0 0;
    }
}

.contact__form__inner {
    padding: 35px 40px;
    border-radius: $border-radius;
    background-color: #eef9f9;

    .gfield {
        input,
        select,
        textarea {
            border: 1px solid transparent;
            background-color: #fff;
        }

        textarea {
            max-height: 180px;
        }
    }
}

.contact__form__title {
    display: block;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 700;
}

.contact__form__text {
    display: block;
    margin-bottom: 25px;
}

.contact__company {
    margin-bottom: 2px;
    font-family: $font-primary;
    font-size: $body-font-size;
    color: $body-font-color;
}

.contact__address {
    margin-bottom: 1.5rem;

    span {
        display: block;
    }
}

.contact__details--location {
    display: inline-block;
    width: 100%;
}

.contact__kvk__location {
    float: left;
    margin-right: 10px;
    font-weight: bold;
}

.contact__accessibility {
    margin: 0 0 20px;
    padding: 0;
    list-style: none;
    line-height: 31px;

    a {
        span {
            text-decoration: underline;
            color: $secondarycolor;
        }

        &:hover {
            span {
                text-decoration: none;
                color: $body-font-color !important;
            }
        }
    }
}

.contact__icon {
    margin-right: 10px;

    svg {
        width: 16px;
        height: 16px;
        color: $primarycolor;
        fill: $primarycolor;
    }
}

.contact__line {
    display: flex;
    line-height: 31px;
}

.contact__link {
    transition: all 0.3s;
    color: $body-font-color;
}

.contact__details {
    margin-bottom: 20px;

    .contact__line {
        margin-bottom: 3px;
    }
}

.contact__email {
    &:hover {
        .contact__link {
            color: $primarycolor;
        }
    }
}

.contact__line__col {
    width: 105px;
    font-weight: bold;
}

.contact__image {
    margin-top: 50px;
}

@media screen and (max-width: 1360px) {
    .gform_body {
        #field_1_8 {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}

@media screen and (max-width: 1199px) {
    .contact__form__inner {
        padding: 35px;
    }

    .contact .childcare {
        padding-top: 50px;
    }
}

@media screen and (max-width: 991px) {
    .contact__main {
        margin-bottom: 40px;
    }

    .contact__main,
    .contact__form {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media screen and (max-width: 767px) {
    .contact__form__inner {
        padding: 20px 30px;

        .gfield {
            input,
            select,
            textarea {
                ::placeholder {
                    font-size: 14px !important;
                }
            }
        }
    }

    ::placeholder {
        font-size: 14px !important;
    }

    .contact__accessibility {
        line-height: 1.7;
    }

    .contact__line {
        line-height: 1.5;
    }

    .contact__details {
        margin-bottom: 0;
    }

    .contact .childcare {
        padding-top: 0;
    }
}

@media screen and (max-width: 575px) {

}
