.page-wrap {
    position: relative;

    &::before {
        position: absolute;
        z-index: 0;
        top: 40%;
        left: 0;
        display: inline-block;
        width: 9.6vw;
        height: 41.6vw;
        content: "";
        pointer-events: none;
        background: url(../../svg/shapes-left.svg) no-repeat left top;
        background-size: contain;
    }

    .childcare {
        padding-top: 50px;
    }
}

.page-wrap__inner {
    position: relative;
    margin-top: -125px;
    padding: 85px 100px;
    border-radius: 50px;
    background-color: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);

    &--location {
        padding: 65px 70px 100px 95px;
    }
}

.page-wrap__inner--news {
    padding: 65px 100px 70px 95px;

    a:not(.btn) {
        text-decoration: underline;
        color: $secondarycolor;

        &:hover {
            text-decoration: none;
            color: $body-font-color;
        }
    }
}

.page-wrap__inner--news.page-wrap__inner--news__single--blog {
    padding: 0;

    .loc-content {
        padding: 50px 100px 100px 95px;
    }
}

.back_to__archive__wrap {
    position: absolute;
    top: -80px;
    margin-left: -10px;
}

.page-wrap__inner--news__single--blog {
    .back_to__archive__wrap {
        margin-left: 11px;
    }
}

.back__to__archive {
    position: relative;
    font-size: 22px;
    font-weight: 300;
    text-decoration: underline;
    //padding-left: 46px;
    color: #fff !important;
    background-color: transparent;

    &:hover,
    &:focus {
        text-decoration: none;
        background-color: transparent !important;
    }

    &::before {
        left: 11px;
    }
}

.back__to__archive__bottom {
    margin-top: 30px;
    display: flex;
    position: relative;
    font-size: 22px;
    font-weight: 300;
    text-decoration: underline;
    //padding-left: 46px;
    color: #64c2c7 !important;
    background-color: transparent;

    &:hover,
    &:focus {
        text-decoration: none;
        background-color: transparent !important;
    }

    &::before {
        left: 11px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' focusable='false' data-prefix='far' data-icon='angle-left' class='svg-inline--fa fa-angle-left fa-w-6' role='img' viewBox='0 0 192 512'%3E%3Cpath fill='%2364c2c7' d='M4.2 247.5L151 99.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17L69.3 256l118.5 119.7c4.7 4.7 4.7 12.3 0 17L168 412.5c-4.7 4.7-12.3 4.7-17 0L4.2 264.5c-4.7-4.7-4.7-12.3 0-17z'/%3E%3C/svg%3E");
    }
}

.tag__label {
    margin-right: 10px;
    font-size: 22px;
    font-weight: 300;
}

.tags__list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    .tag__item {
        display: inline-block;
        padding: 0 5px;

        a {
            font-size: 22px;
        }
    }
}

/* Pagination numbered */
.pagination__numbers {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 50px;
    border-top: 1px solid #eee;

    .page-numbers {
        display: flex;
        align-items: center;
        justify-content: center;
        float: left;
        width: 30px;
        height: 30px;
        margin: 0 3px;
        font-size: 15px;
        text-decoration: none;
        color: #fff;
        border-radius: 4px;
        background: $primarycolor;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        &:hover,
        &:focus {
            text-decoration: none;
            background-color: darken($primarycolor, 5);
        }

        &.current {
            background-color: darken($primarycolor, 5);
        }
    }
}

.single__date {
    display: block;
    margin-bottom: 25px;
    font-size: 18px;
    font-weight: 700;
    color: #505050;

    svg {
        position: relative;
        top: -2px;
        width: 14px;
        height: 16px;
        margin-right: 8px;
        color: #505050;
        fill: #505050;
    }
}

.single-post {
    h1 {
        margin-bottom: 5px;
    }
}

.single__cat {
    margin-bottom: 20px;
    font-size: 27px;
    font-weight: 600;
}

.single__cat--blogs {
    color: #36638a;
}

.single__text {
    margin-bottom: 28px;
}

.page__tags {
    padding: 75px 70px 75px 95px;
    border-radius: 0 0 50px 50px;
    background-color: #eef9f9;
}

.page__tags__inner {
    display: flex;
    align-items: center;
}

@media screen and (max-width: 1199px) {
    .page-wrap__inner {
        margin-top: -75px;
        padding: 60px 80px 60px;
    }

    .page-wrap__inner--news.page-wrap__inner--news__single--blog {
        padding: 0;

        .loc-content {
            padding: 60px 80px 60px;
        }
    }

    .page__tags {
        padding: 60px 80px;
        border-radius: 0 0 50px 50px;
        background-color: #eef9f9;
    }

    .tag__label {
        font-size: 19px;
    }

    .tags__list {
        .tag__item {
            a {
                font-size: 19px;
            }
        }
    }

    .back_to__archive__wrap {
        top: -70px;
    }
}

@media screen and (max-width: 1070px) {
    .page-wrap__vacancy__archive {
        .quote__block__worker {
            padding-right: 95px;
            padding-left: 95px;
        }
    }
}

@media screen and (max-width: 991px) {
    .single__date {
        font-size: 16px;
    }

    .tag__label {
        font-size: 16px;
    }

    .tags__list {
        .tag__item {
            a {
                font-size: 16px;
            }
        }
    }

    .page-wrap__inner {
        padding: 40px 40px 60px;
    }

    .page-wrap__inner--news.page-wrap__inner--news__single--blog {
        padding: 0;

        .loc-content {
            padding: 40px 40px 60px;
        }
    }

    .page__tags {
        padding: 40px 40px;
    }

    .page-wrap__inner--news__single--blog {
        .loc-content {
            .back_to__archive__wrap {
                left: 34px;
                margin-left: 0;
            }
        }
    }

    .page-wrap__vacancy__archive {
        .quote__block__worker {
            padding-right: 0;
            padding-left: 0;
        }
    }
}

@media screen and (max-width: 900px) {
    .page-wrap::before {
        display: none;
    }
}

@media screen and (max-width: 767px) {
    .page-wrap__inner {
        margin-top: 0;
        padding: 30px 10px;
        border-radius: 0;
        background-color: transparent;
        box-shadow: none;
    }

    .page-wrap__inner--news.page-wrap__inner--news__single--blog {
        padding: 0;

        .loc-content {
            padding: 30px 0 0;
        }
    }

    .page-wrap__inner--news__single--blog {
        .loc-content {
            .back_to__archive__wrap {
                left: 0;
                margin-left: 0;
            }
        }
    }

    .page__tags__inner {
        align-items: flex-start;
        flex-direction: column;
    }

    .page__tags {
        margin-right: -20px;
        margin-left: -20px;
        padding: 30px;
        border-radius: 0;
        background-color: #eef9f9;
    }

    .tag__label {
        margin-left: 5px;
    }

    .back_to__archive__wrap {
        top: -45px;
    }

    .back__to__archive {
        padding-left: 30px;
        font-size: 15px;
    }

    .page-wrap .childcare {
        padding-top: 20px;
    }

    .single__date {
        margin-bottom: 10px;
        font-size: 13px;

        svg {
            height: 14px;
        }
    }

    .single__cat {
        margin-bottom: 10px;
        font-size: 18px;
    }
}

@media screen and (max-width: 575px) {
    .page-wrap--default .childcare {
        padding-top: 10px;
    }
}
