.floating__icons {
    position: fixed;
    z-index: 10;
    top: 60%;
    right: 0;
    width: 46px;
    padding: 16px 0 14px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transform: translateY(-60%);
    border-radius: 11px 0 0 11px;
    background-color: #e0dd00;
}

.floating__icon__call {
    line-height: 0;

    svg {
        height: 21px !important;
        width: 21px !important;
        color: #fff;
        transition: all 0.3s ease;
        transform: rotate(90deg);
    }
}

.floating__icon__email {
    line-height: 0;
    margin-top: 14px;

    svg {
        height: 21px !important;
        width: 21px !important;
        color: #fff;
        transition: all 0.3s ease;
    }
}

.floating__icon__whatsapp {
    line-height: 0;
    margin-top: 14px;

    svg {
        height: 24px !important;
        width: 24px !important;
        color: #fff;
        transition: all 0.3s ease;
    }
}

body {
    &.menu--open {
        overflow: hidden;

        .parent__dropdown__list {
            &.show {
                display: block;
            }
        }
    }
}

.header,
.logo__default,
ul.sub-menu,
.menu ul li a,
.block__readmore {
    transition: all 0.3s linear;
}

.header__vacatures__btn {
    position: relative;
    margin-right: 20px;

    svg {
        display: none;
    }
}

.header__vacatures__btn__amount {
    position: absolute;
    right: -27px;
    top: -13px;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-color: #ffa600;
    font-size: 12px;
    color: #fff;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
}

.subscribe__btn {
    svg {
        display: none;
    }
}

.mobile__cta__menu {
    display: none;
}

.menu-toggler {
    height: 74px;
    padding: 0 39px 0 64px;
    transition: background 0.3s linear, height 0.3s linear;
    font-size: 22px;
    font-weight: 600;
    color: #197611;
    border: 2px solid $primarycolor;
    border-radius: $border-radius;
    outline: 0;
    background: url(../../svg/bars.svg) no-repeat 26px center;
    background-size: 20px 30px;

    &--open {
        display: none;
    }

    &:focus,
    &:hover,
    &:active {
        color: $white;
        outline: 0;
        background: $primarycolor url(../../svg/bars-white.svg) no-repeat 26px center;
        background-size: 20px 30px;
    }
}

.logo__default {
    width: 177px;

    svg {
        width: 177px;
        height: 103px;
    }
}

.header {
    position: fixed;
    z-index: 4;
    width: 100%;
    padding: 33px 0;
    background: $white;
}

.navbar-brand {
    padding: 0;
}

.header .container-fluid {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1720px;
}

.search__form {
    position: relative;
    display: flex;
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 7px;
    cursor: pointer;

    .search__btn {
        pointer-events: none;
    }
}

.search__btn {
    width: 30px;
    height: 30px;
    border: none;
    outline: 0;
    background: url(../../svg/search.svg) no-repeat center;
    background-size: 30px 30px;
}

.search__form .form-control {
    display: none;
    color: #197611;
    border: 2px solid $primarycolor;
}

.search__form--show .form-control {
    display: block;
    height: 38px;
    padding: 0 35px 0 10px;
    font-size: 15px;
}

.search__form--show .search__btn {
    position: absolute;
    top: 50%;
    right: 9px;
    width: 20px;
    height: 20px;
    transform: translateY(-50%);
    pointer-events: initial;
    outline: 0;
    background-size: 20px 20px;
}

.language-switcher {
    display: flex;
    align-items: center;
    padding: 0 10px;
    font-size: 16px;
    font-weight: 600;

    a {
        position: relative;
        display: inline-block;
        padding: 0 16px;
        text-transform: uppercase;
        color: $primarycolor;

        &:not(:last-child)::after {
            position: absolute;
            right: -1px;
            display: inline-block;
            content: "|";
            font-weight: 400;
        }

        &:hover,
        &:focus {
            color: $body-font-color;

            &::after {
                color: $primarycolor;
            }
        }
    }
}

.header__rightcol {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    white-space: nowrap;
}

.menu__logo {
    width: 177px;

    svg {
        width: 177px;
        height: 103px;
    }
}

.header__phone {
    margin-right: 50px;
    padding-left: 30px;
    font-size: 21px;
    font-weight: 600;
    color: $white;
    background: url(../../svg/phone-alt.svg) no-repeat left center;
    background-size: 20px 20px;

    &:hover {
        color: $body-font-color;
        background: url(../../svg/phone-alt-body-color.svg) no-repeat left center;
        background-size: 20px 20px;
    }
}

.menu__btn {
    display: flex;
    align-items: center;
    height: 74px;
    margin-right: 30px;
    padding: 0 30px 0 61px;
    font-size: 22px;
    font-weight: 600;
    color: $white;
    border: 2px solid $white;
    border-radius: $border-radius;
    background: url(../../svg/user.svg) no-repeat 26px center;
    background-size: 20px 20px;
    box-shadow: 0 3px 6px rgba($black, 0.2);

    &:focus,
    &:hover,
    &:active {
        color: #197611;
        background: url(../../svg/user-greenish.svg) no-repeat 26px center;
        background-color: $white;
        background-size: 20px 20px;
    }
}

.menu {
    position: fixed;
    top: 170px;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    background: transparent;

    ul {
        margin: 0;
        padding: 0;
        list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
        transform: translateX(-50%);
        text-align: right;

        li {
            position: relative;
            color: rgba($white, 0.5);

            a {
                position: relative;
                display: inline-block;
                padding: 11px 55px 11px 0;
                font-size: 27px;
                color: rgba($white, 0.5);
                border-right: 2px solid rgba($white, 0.5);

                &:hover,
                &:focus,
                &:active {
                    color: $white;

                    &::after {
                        position: absolute;
                        top: 50%;
                        right: 0;
                        display: inline-block;
                        height: 40px;
                        content: "";
                        transform: translate(2px, -50%);
                        border-right: 2px solid $white;
                    }
                }
            }

            &:hover {
                a {
                    color: $white;
                }
            }
        }
    }
}

ul.sub-menu {
    position: absolute;
    top: 18px;
    right: 0;
    display: none;
    transform: translateX(100%);
    text-align: left;

    li {
        a {
            padding: 0 0 11px 55px;
            font-size: 22px;
            color: $white;
            border: none;

            &:hover,
            &:focus,
            &:active {
                color: #197611;

                &::after {
                    display: none;
                }
            }
        }
    }
}

.menu-angle {
    display: none;
}

.menu-shrink:not(.menu--open) .header {
    padding: 10px 0;
    background: rgba($white, 0.95);
    box-shadow: 0 0 5px;
}

.menu-shrink:not(.menu--open) .logo__default {
    width: 125px;
}

.menu__logo,
.menu--open .logo__default,
.header__phone,
.menu__btn {
    display: none;
}

.menu--open .menu,
.menu--open .menu__logo,
.menu--open .header__phone,
.menu--open .menu__btn {
    display: flex;
}

.menu--open .menu {
    overflow: auto;
}

.menu--open .menu-toggler {
    padding: 0 25px 0 64px;
    border-color: $white;
    background: $white url(../../svg/times.svg) no-repeat 26px center;
    background-size: 20px 30px;
    box-shadow: 0 3px 6px rgba($black, 0.2);

    &--closed {
        display: none;
    }

    &--open {
        display: block;
    }

    &:focus,
    &:hover,
    &:active {
        color: #197611;
        box-shadow: 0 3px 6px rgba($black, 0.1);
    }
}

.menu--open .search__btn {
    background: url(../../svg/search-white.svg) no-repeat center;
    background-size: 30px 30px;

    &:hover {
        background: url(../../svg/search-body-color.svg) no-repeat center;
    }
}

.menu--open .search__form--show .search__btn {
    background: url(../../svg/search.svg) no-repeat center;
    background-size: 18px 18px;
}

.menu--open .language-switcher a {
    color: $white;

    &:hover,
    &:focus {
        color: $body-font-color;

        &::after {
            color: $white;
        }
    }
}

.menu--open .header {
    background: transparent;
}

//.menu-shrink:not(.menu--open) .menu-toggler {
//    height: 54px;
//}

.menu--open {
    &::before {
        position: fixed;
        z-index: 2;
        top: 0;
        right: 0;
        left: 0;
        display: inline-block;
        width: 100%;
        height: 100%;
        content: "";
        background-color: rgba($primarycolor, 0.9);
        background-size: cover;
        backdrop-filter: blur(15px);
    }

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        &::before {
            background-color: $primarycolor;
        }
    }
}

.parent__dropdown {
    position: relative;
    z-index: 2;
}

.parent__dropdown__list {
    position: absolute;
    top: calc(100% - 8px);
    right: 30px;
    display: none;
    overflow: hidden;
    width: calc(100% - 30px);
    border-radius: 0 0 10px 10px;
    background-color: #fff;
}

.parent__dropdown__item {
    display: block;
    padding: 12px 25px;
    font-size: 20px;
    font-weight: 500;

    &:not(:last-child) {
        border-bottom: 1px solid #e3e3e3;
    }

    &:hover {
        color: darken($secondarycolor, 10);
    }
}

.menu-item-has-children__overlay {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.banner__location {
    display: flex;
}

@media screen and (max-width: 1599px) {
    .menu {
        top: 143px;

        ul {
            li {
                a {
                    padding: 10px 45px 10px 0;
                    font-size: 24px;
                }
            }
        }
    }

    ul.sub-menu li a {
        padding: 0 0 11px 45px;
        font-size: 20px;
    }

    .header__phone {
        margin-right: 30px;
        font-size: 20px;
    }

    .header {
        padding: 20px 0;
    }

    .menu-toggler,
    .menu--open .menu-toggler,
    .menu__btn {
        height: 64px;
        padding: 0 29px 0 54px;
        font-size: 19px;
        background-position: 21px center;
        background-size: 18px 18px;

        &:focus,
        &:hover,
        &:active {
            background-position: 21px center;
            background-size: 18px 18px;
        }
    }

    .search__btn,
    .menu--open .search__btn {
        background-size: 23px 23px;
    }
}

@media screen and (max-width: 1199px) {
    .menu {
        top: 92px;

        ul {
            li {
                a {
                    padding: 5px 30px 5px 0;
                    font-size: 20px;
                }
            }
        }
    }

    .search__form .form-control {
        border-width: 1px;
    }

    ul.sub-menu li a {
        padding: 0 0 0 30px;
        font-size: 18px;
    }

    .header__phone {
        margin-right: 10px;
        font-size: 17px;
    }

    .header {
        padding: 10px 0;
    }

    .logo__default,
    .menu__logo {
        width: 125px;

        svg {
            width: 125px;
            height: 100px;
        }
    }

    .menu-toggler,
    .menu--open .menu-toggler,
    .menu__btn {
        height: 54px;
        margin-right: 0;
        padding: 0 24px 0 44px;
        font-size: 17px;
        background-position: 15px center;
        background-size: 16px 16px;

        &:focus,
        &:hover,
        &:active {
            background-position: 15px center;
            background-size: 16px 16px;
        }
    }

    .menu__btn {
        margin-right: 10px;
    }

    .menu-toggler,
    .menu--open .menu-toggler {
        background-size: 24px 24px;

        &:focus,
        &:hover,
        &:active {
            background-size: 24px 24px;
        }
    }

    .search__form {
        max-width: 130px;
    }

    .search__btn,
    .menu--open .search__btn {
        background-size: 20px 20px;
    }

    .language-switcher {
        padding: 0;
    }
}

@media screen and (max-width: 991px) {
    .header__phone {
        overflow: hidden;
        width: 30px;
        background-position: center;
    }

    .menu__btn,
    .menu--open .menu__btn,
    .menu-toggler,
    .menu--open .menu-toggler {
        overflow: hidden;
        width: 47px;
        height: 47px;
        padding: 0;
        text-indent: 50px;
        border: none;
        border-radius: 3px;
        background-position: center;
        box-shadow: none;

        &:focus,
        &:hover,
        &:active {
            background-position: center;
        }
    }

    .subscribe__btn,
    .header__vacatures__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 46px;
        height: 46px;
        padding: 0;

        svg {
            display: block;
            width: 18px;
            height: 18px;
        }

        span {
            display: none;
        }
    }

    .menu--open .menu__btn,
    .menu__btn {
        width: 47px;
        position: relative;
        text-indent: 0;
        border: 2px solid #fff;

        span {
            display: none;
        }
    }

    .menu-toggler,
    .menu--open .menu-toggler {
        width: 47px;
        height: 47px;
        padding: 0;
        border-radius: 3px;
    }
}

@media screen and (max-width: 767px) {
    .search__form {
        max-width: 110px;
    }

    .menu-item-has-children__open {
        .menu-item-has-children__overlay {
            pointer-events: none;
        }

        .sub-menu {
            position: relative;
            top: 0;
            display: block;
            height: auto;
            padding: 5px 0 10px;
            border-radius: 5px;
            background-color: darken($primarycolor, 5);

            li {
                padding-right: 25px;
                padding-left: 25px;
            }
        }
    }

    .header__vacatures__btn__amount {
        right: -15px;
        top: -12px;
        width: 19px;
        height: 19px;
        font-size: 8px;
    }
}

@media screen and (max-width: 575px) {
    .language-switcher {
        position: fixed;
        bottom: 10px;
        left: 14px;
        display: none;
        z-index: 999;
        background-color: $primarycolor;
    }

    .header__vacatures__btn {
        display: none;
        color: #fff;
        background-color: transparent;
        border: 2px solid #fff;
        font-size: 12px;
        margin-right: 10px;
        padding: 10px 15px;

        &:hover {
            color: $primarycolor;
            background-color: #fff;
        }
    }

    .subscribe__btn {
        display: none;
    }

    .mobile__cta__menu {
        display: flex;
        flex-direction: column;
        padding: 18px;
        width: 100%;
        gap: 9px;
    }

    .header__vacatures__btn__mobile,
    .subscribe__btn__mobile {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        width: 100%;
        max-width: 320px;

        svg {
            display: block;
            width: 18px;
            height: 18px;
        }
    }

    .menu--open {
        .menu {
            flex-direction: column;
        }
    }

    .menu--open .header__vacatures__btn {
        display: none;
    }

    .menu--open .language-switcher {
        display: block;
    }

    .menu-shrink:not(.menu--open) .header {
        //background-color: rgba($primarycolor, 0.95);
    }

    .menu-shrink:not(.menu--open) .menu-toggler {
        height: 43px;
    }

    .menu-toggler {
        background: $white url(../../svg/bars.svg) no-repeat center center;
        background-size: 21px 21px;
    }

    .menu-shrink {
        .menu-toggler {
            background: $primarycolor url(../../svg/bars-white.svg) no-repeat center center;
            background-size: 21px 21px;
        }
    }

    .menu-toggler,
    .menu--open .menu-toggler {
        width: 43px;
        height: 43px;
    }

    .menu {
        top: 81px;
        align-items: flex-start;
        justify-content: left;
        overflow: auto;
        max-height: calc(100vh - 150px);
        margin-top: 30px;

        ul {
            left: 0;
            width: 100%;
            transform: none;
            text-align: left;

            li {
                padding-right: 18px;
                padding-left: 18px;

                a {
                    display: block;
                    padding: 9px 30px 9px 0;
                    font-size: 16px;
                    font-weight: 400;
                    color: $white;
                    border: none;
                }

                &:focus,
                &:hover,
                &:active {
                    a::after {
                        display: none;
                    }
                }
            }
        }

        .sub-menu {
            li {
                padding-right: 30px;
                padding-left: 30px;
            }
        }
    }

    .menu-item-has-children {
        display: block;

        > a {
            background: url(../../svg/chevron-down.svg) no-repeat right center;
            background-size: 10px 10px;
        }

        &.menu-item-has-children__open {
            > a {
                background: url(../../svg/chevron-up.svg) no-repeat right center;
                background-size: 10px 10px;
            }
        }
    }

    //ul.sub-menu {
    //    position: static;
    //    background: #4fa751;
    //    border-radius: 5px;
    //    padding: 0 20px;
    //    height: 0;
    //    overflow: hidden;
    //    margin: 0;
    //    display: block;
    //}

    .menu ul li.menu-item-has-children.sub-menu--open ul.sub-menu {
        overflow: auto;
        height: auto;
    }

    .menu-item-has-children.sub-menu--open {
        > a {
            background: url(../../svg/chevron-up.svg) no-repeat right center;
            background-size: 10px 10px;
        }
    }

    .header {
        padding-top: 28px;
        background: transparent;
    }

    .logo__default {
        display: none;
        width: 70px;

        svg {
            width: 70px;
            height: 41px;
        }
    }

    .menu__logo {
        display: block;
        width: 70px;

        svg {
            width: 70px;
            height: 41px;
        }
    }

    .menu-shrink .logo__default {
        display: block;
    }

    .menu-shrink .menu__logo {
        display: none;
    }

    .header .container-fluid {
        padding-right: 30px;
        padding-left: 30px;
    }

    .search__form {
        max-width: 103px;
    }

    .search__form .form-control {
        font-size: 12px;
    }

    .search__form .search__btn {
        background: url(../../svg/search-white.svg) no-repeat center center;
        background-size: 21px 21px;
    }

    .menu-shrink .search__form .search__btn {
        background: url(../../svg/search.svg) no-repeat center center;
        background-size: 21px 21px;
    }

    .template-locaties-data {
        .logo__default {
            display: block;
        }

        .menu__logo {
            display: none;
        }

        .search__form .search__btn {
            background: url(../../svg/search.svg) no-repeat center center;
            background-size: 21px 21px;
        }

        &.menu--open {
            .logo__default {
                display: none;
            }

            .menu__logo {
                display: block;
            }

            .search__form .search__btn {
                background: url(../../svg/search-white.svg) no-repeat center center;
                background-size: 21px 21px;
            }
        }
    }

    .menu--open.menu-shrink {
        .search__form .search__btn {
            background: url(../../svg/search-white.svg) no-repeat center center;
            background-size: 21px 21px;
        }

        .logo__default {
            display: none;
        }

        .menu__logo {
            display: block;
        }

        .menu-toggler {
            background: $white url(../../svg/times.svg) no-repeat center center;
            background-size: 21px 21px;
        }
    }
}

@media screen and (max-width: 400px) {
    .header .container-fluid {
        padding-right: 20px;
        padding-left: 20px;
    }
}

@media screen and (max-width: 575px) {
    //.page-locaties-data {
    //    .header {
    //        background: $primarycolor;
    //        padding-bottom: 28px;
    //    }
    //}
}

@media screen and (max-width: 359px) {
    .search__form {
        max-width: 103px;
    }

    .header__phone {
        display: none !important;
    }

    .menu-shrink:not(.menu--open) .logo__default {
        width: 107px;
    }
}

@media screen and (min-width: 575px) {
    .menu ul li:hover ul.sub-menu {
        display: block;
    }
}
