.front__content {
    position: relative;
    padding: 0 0 0;
}

.front__intro {
    margin-top: 90px;

    h1 {
        max-width: 720px;
        margin-right: auto;
        margin-left: auto;
    }
}

.front__content__location {
    margin-top: 25px;
}

// Banner
.banner,
.innerbanner {
    position: relative;
    overflow: hidden;
    padding-top: 169px;
    transition: padding 0.2s linear;

    img {
        width: 100%;
        max-width: 100%;
    }

    .slick-list {
        overflow: initial;
    }
}

.banner__item {
    position: relative;

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        height: 100%;
        content: '';
        pointer-events: none;
        background-image: linear-gradient(269deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.55) 100%);
    }
}

.menu-shrink .banner,
.menu-shrink .innerbanner {
    padding-top: 0;
}

.innerbanner__wrapper {
    position: relative;
}

.innerbanner__text {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    text-decoration: underline;
    color: $white;

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        width: 70%;
        height: 100%;
        content: "";
        background: linear-gradient(to right, rgba($black, 0.6) 0%, rgba($black, 0) 100%);
    }

    a {
        color: $white;
    }
}

.innerbanner__text .container-fluid {
    position: relative;
    z-index: 1;
    margin-bottom: 165px;
    padding-left: 112px;
}

//.banner__text {
//    position: absolute;
//    top: 45%;
//    right: 0;
//    left: 86px;
//    width: 100%;
//    max-width: 860px;
//    padding: 0 65px;
//    transform: translateY(-50%);
//    font-size: 58px;
//    font-weight: 500;
//    line-height: 66px;
//    text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
//    color: $white;
//}

.slick-next {
    right: 30px;
}

.slick-prev {
    left: 30px;
}

.slick-arrow {
    z-index: 1;
    top: 38%;
    width: 20px;
    height: 53px;
    transform: translateY(-50%);

    &::before {
        display: none;
    }
}

.slick-slide {
    img {
        max-width: 100%;
    }
}

.news {
    position: relative;

    &::before {
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        display: inline-block;
        width: 15vw;
        height: 41.6vw;
        content: "";
        pointer-events: none;
        background: url(../../svg/shapes-left.svg) no-repeat left top;
        background-size: contain;
    }
}

.section {
    display: flex;
    max-width: 1066px;
    margin: auto;
    padding: 95px 40px 86px;
}

.section__leftcol {
    padding: 20px 95px 0 0;
}

.section__heading {
    margin-bottom: 10px;
    font-size: 31px;
    font-weight: 400;
    line-height: 38px;
    color: $secondarycolor;
}

.section__title {
    margin-bottom: 15px;
    font-family: $font-primary;
    font-size: 38px;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    color: $secondarycolor;
}

.section__desc {
    text-align: center;
    color: $body-font-color;
}

.section__date {
    font-size: 14px;
    font-weight: 700;
    color: #464646;
}

.section__content {
    margin-top: 40px;
    color: $body-font-color;

    span {
        color: $secondarycolor;
    }
}

.section__image {
    position: relative;
    width: 330px;
    min-width: 330px;
    height: 330px;
    border-radius: 100%;

    img {
        position: relative;
        z-index: 1;
        width: 100%;
        max-width: 100%;
        border-radius: 100%;
    }

    &::before {
        position: absolute;
        z-index: 0;
        top: -30px;
        right: -40px;
        display: inline-block;
        width: 110px;
        height: 145px;
        content: "";
        pointer-events: none;
        background: url(../../svg/bg1.svg) no-repeat;
        background-size: auto;
    }
}

.typecare {
    max-width: 1150px;
    margin: auto;
    padding: 75px 0;

    &:hover {
        img {
            transform: none;
        }
    }

    &.typecare__top {
        position: relative;
        z-index: 1;
        padding: 0;
        margin-top: -50px;
    }
}

.typecare .typecare__item {
    &:nth-child(1) {
        .block__title {
            color: #36638a;
        }
    }

    &:nth-child(3) {
        .block__title {
            color: $secondarycolor;
        }
    }
}

.typecare .section__desc {
    margin: 0 auto;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @extend .section__title;

        margin-top: 0;
    }

    p:last-of-type {
        margin-bottom: 0;
    }
}

.typecare__bottom {
    border-bottom: 1px solid #e1e1e1;
}

.typecare .block {
    text-align: center;
    margin-top: 0;
    border: 0;
    box-shadow: 0 17px 26px rgba(#000, 0.06);

    &:hover {
        box-shadow: 0 17px 26px rgba(#000, 0.12);
    }
}

.typecare .block__content {
    align-items: center;
    padding: 43px 35px;
}

.typecare .block__title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: 500;
    text-align: left;
    color: $primarycolor;

    span {
        display: inline-block;
    }
}

.typecare .block__icon {
    display: inline-block;
    margin-right: 20px;
}

.typecare .block__summary {
    height: auto;
    margin: 15px 0 22px;
    font-size: 19px;
    font-weight: 400;
}

.typecare .block__readmore {
    margin-top: auto;
    padding-right: 30px;
    font-size: 20px;
    font-weight: 600;
    color: #464646;
    background: url(../../svg/chevron-right.svg) no-repeat right center;
    background-size: 7px 14px;
}

.front__location {
    position: relative;
    z-index: 1;
    max-width: 1190px;
    margin-right: auto;
    margin-left: auto;
    padding: 57px 60px 43px 60px;
    border-radius: 20px;
    background-color: $primarycolor;

    .wpgmp_map_container {
        .location_listing1 {
            display: none;
        }
    }
}

.front__location__title {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;

    svg {
        width: 85px;
        height: 85px;
        margin-right: 20px;
    }
}

.front__location__title__txt {
    display: flex;
    flex-direction: column;
    line-height: 1.3;

    .title {
        font-size: 38px;
        font-weight: 500;
    }
}

.block__summary__mobile {
    display: none !important;
}

.front__news__txt {
    p {
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.front__news__intro {
    text-align: center;
    max-width: 920px;
    margin: 0 auto;
}

.front__news__all {
    text-align: center;
    margin-top: 40px;

    a {
        color: $secondarycolor;
        text-decoration: underline;

        &:hover {
            color: $primarycolor;
        }
    }
}

.front__news__title {
    font-family: $font-primary;
    font-size: 38px;
    font-weight: 600;
    line-height: normal;
    color: $primarycolor;
}

.front__news__items {
    max-width: 1380px;
    margin: 0 auto;
}

.front__news__container {
    padding: 80px 0 30px 0;
}

.section--front__news {
    padding-top: 45px;
}

.front__location__search {
    display: flex;
    justify-content: center;
    margin-top: 75px;

    .wpgmp_map_container .wpgmp_map_parent {
        display: none;
    }

    .wpgmp_map_container div.categories_filter select {
        border-color: #fff;
        background-color: #fff;

        &:focus {
            border-color: #fff;
        }
    }

    .wpgmp_map_container div.wpgmp_search_form input.wpgmp_search_input {
        border: 1px solid #fff;
        background-color: #fff;

        &:focus {
            border-color: #fff;
        }
    }
}

.wpgmp_before_listing {
    display: none;
}

.wpgmp_map_container {
    > input {
        display: none;
    }

    .wpgmp_filter_wrappers {
        background: transparent;
    }
}

.read__more__front__news {
    display: block !important;
}

@media screen and (max-width: 1599px) {
    .innerbanner__text {
        font-size: 20px;
    }

    .innerbanner__text .container-fluid {
        margin-bottom: 130px;
        padding-left: 80px;
    }

    .section__title {
        margin-bottom: 10px;
        font-size: 35px;
    }

    body {
        font-size: 19px;
    }

    .typecare {
        padding: 60px 0;
    }

    .typecare .block__content {
        padding: 40px 30px;
    }

    .front__news__title {
        font-size: 35px;
    }

    .typecare .block__readmore {
        font-size: 18px;
        background-size: 8px 16px;
    }

    .typecare .block__title {
        font-size: 22px;
    }

    .typecare .block__summary {
        font-size: 18px;
    }

    .news {
        &::before {
            width: 12vw;
        }
    }

    .section__image {
        width: 300px;
        min-width: 300px;
        height: 300px;
    }

    .section {
        padding-top: 85px;
        padding-bottom: 85px;
    }

    .banner,
    .innerbanner {
        padding-top: 143px;
    }

    //.banner__text {
    //    font-size: 55px;
    //    line-height: 60px;
    //}

    .slick-next {
        right: 20px;
    }

    .slick-prev {
        left: 20px;
    }

    .section__leftcol {
        padding: 20px 75px 0 0;
    }

    .section__heading {
        font-size: 25px;
        line-height: normal;
    }

    .section__content {
        margin-top: 30px;
    }

    .section--front__news {
        padding-top: 15px;
    }
}

@media screen and (max-width: 1199px) {
    .innerbanner__text {
        font-size: 18px;
    }

    .front__news__container {
        padding: 80px 0 70px 0;
    }

    .innerbanner__text .container-fluid {
        margin-bottom: 90px;
        padding-left: 50px;
    }

    .section__title {
        margin-bottom: 5px;
        font-size: 30px;
    }

    body {
        font-size: 18px;
    }

    .block__icon {
        display: inline-block;
        width: 40px;

        img {
            width: 100%;
        }
    }

    .front__news__title {
        font-size: 30px;
    }

    .front__location__search {
        margin-top: 45px;

        .wpgmp_filter_wrappers {
            margin-bottom: 0;
        }
    }

    .typecare {
        padding: 50px 0;
    }

    .typecare .block__content {
        padding: 30px;
    }

    .block__summary__front__news {
        margin-bottom: 10px !important;
    }

    .typecare .block__title {
        font-size: 20px;
    }

    .typecare .block__summary {
        font-size: 16px;
    }

    .typecare .block__readmore {
        font-size: 16px;
        background-size: 7px 14px;
    }

    .news {
        &::before {
            display: none;
        }
    }

    .section__image {
        width: 250px;
        min-width: 250px;
        height: 250px;
    }

    .section__heading {
        font-size: 22px;
    }

    .section__content {
        margin-top: 20px;
    }

    .section__leftcol {
        padding: 20px 55px 0 0;
    }

    .section {
        padding-top: 65px;
        padding-bottom: 65px;
    }

    .section--front__news {
        padding-top: 15px;
        padding-left: 70px;
    }

    .banner,
    .innerbanner {
        padding-top: 120px;
    }

    //.banner__text {
    //    font-size: 45px;
    //    line-height: 50px;
    //}

    .slick-arrow {
        svg {
            width: 15px;
        }
    }

    .typecare .section__desc {
        margin: 0 20px;
    }
}

@media screen and (max-width: 991px) {
    .innerbanner__text {
        font-size: 16px;
    }

    .innerbanner__text .container-fluid {
        margin-bottom: 50px;
    }

    .section__title {
        font-size: 25px;
    }

    body {
        font-size: 16px;
    }

    .typecare {
        padding: 40px 0;
    }

    .typecare .block__content {
        padding: 25px;
    }

    .section {
        padding-top: 55px;
        padding-bottom: 55px;
    }

    .section--front__news {
        padding-top: 0;
        padding-left: 0;
    }

    .front__news__title {
        font-size: 25px;
    }

    .front__news__container {
        padding: 50px 0 20px 0;
    }

    .typcare__front__items {
        padding-bottom: 0 !important;
    }

    .typecare__bottom {
        padding-bottom: 45px !important;
    }

    //.banner__text {
    //    left: 30px;
    //    font-size: 40px;
    //    line-height: 45px;
    //}

    .banner {
        .slick-prev {
            left: 30px;
        }

        .slick-next {
            right: 30px;
        }
    }

    .slick-arrow {
        top: 36%;
    }

    .typecare.typecare__top {
        margin-top: 0;
    }

    .circle__top--front {
        top: 0;
    }

    .typecare .block {
        margin-top: 40px;
        border: 1px solid #e5e5e5;
    }
}

@media screen and (max-width: 767px) {
    .block__icon {
        margin-right: 8px;
    }

    .front__news__txt {
        margin-bottom: 30px;
    }

    .front__news__container {
        padding: 30px 0 0 !important;
        margin-top: 0 !important;
    }

    .front__news__all {
        margin-top: -10px;

        a {
            font-size: 17px;
        }
    }

    .innerbanner__text {
        font-size: 15px;
    }

    .typecare .block {
        margin-bottom: 0;
    }

    .innerbanner__text .container-fluid {
        margin-bottom: 10px;
        padding-left: 30px;
    }

    .front__intro {
        margin-top: 40px;
    }

    .typecare {
        padding: 30px 0;

        .block__title {
            flex-direction: column;
        }

        .block__icon {
            margin-right: 0;
        }
    }

    .section {
        padding: 45px 0;
    }

    .section__image {
        display: none;
    }

    .typecare__bottom {
        padding-top: 35px;
        padding-bottom: 35px !important;
    }

    //.banner__text {
    //    top: 50%;
    //    left: 10px;
    //    font-size: 30px;
    //    line-height: 35px;
    //}

    .container-fluid {
        padding-right: 20px;
        padding-left: 20px;
    }

    .slick-arrow {
        top: 50%;
    }

    .banner {
        .slick-prev {
            left: 20px;
        }

        .slick-next {
            right: 20px;
        }
    }

    .section--front__news {
        padding-top: 0;
    }

    .front__news__title {
        padding-left: 0;
    }
}

@media screen and (max-width: 575px) {
    .typecare.typecare__top {
        margin-top: 20px;
    }

    .typecare .typecare__item {
        &:nth-child(1) {
            .block__readmore {
                background-color: #36638a;
            }
        }

        &:nth-child(2) {
            .block__readmore {
                background-color: $primarycolor;
            }
        }
    }

    .front__intro {
        line-height: 1.5;
        margin-top: 30px;

        h1 {
            margin-bottom: 15px;
            font-size: 17px;
        }
    }

    .front__news__title {
        margin-top: 0;
        font-size: 22px;
    }

    .banner {
        .slick-prev {
            left: 10px;
        }

        .slick-next {
            right: 10px;
        }
    }

    .front__content {
        padding-bottom: 30px !important;
    }

    .typecare .block__readmore {
        width: 46px;
        height: 46px;
        padding: 0;
        font-size: 0;
        border-radius: $border-radius;
        background: $secondarycolor url(../../svg/chevron-right-white.svg) no-repeat center;
        background-size: 12px 24px;
    }

    .typecare .block {
        margin: 0 0 15px;
        border-radius: 10px;
        border: 1px solid #d1d1d1;
        box-shadow: none;

        &:hover {
            .block__readmore {
                background-color: $primarycolor;
            }
        }
    }

    .section__title {
        font-size: 17px;
    }

    .news {
        margin-top: 15px;
    }

    body {
        font-size: 14px;
    }

    .typecare .block__summary {
        display: none;
    }

    .block__summary__mobile {
        display: block !important;
        font-size: 15px;
        font-weight: 300;
        line-height: 1.5;
        color: $body-font-color;
    }

    .typecare .block__content {
        flex-direction: row;
        justify-content: space-between;
        padding: 17px 16px 17px 24px;
    }

    .typecare {
        padding: 20px 0 0;

        .block__title {
            flex-direction: row;
            font-size: 16px;
        }

        .block__icon {
            margin-right: 15px;
        }

        img {
            max-width: 35px;
        }
    }

    .typecare__bottom {
        padding-top: 35px;
    }

    .typecare__top {
        .container-fluid {
            max-width: 96%;
        }
    }

    .section__heading {
        margin: 0;
        font-size: 17px;
    }

    .section {
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .section__leftcol {
        padding: 0;
    }

    //.banner__text {
    //    left: 25px;
    //    max-width: 250px;
    //    padding: 0 30px;
    //    transform: none;
    //    font-size: 17px;
    //    font-weight: 500;
    //    line-height: 20px;
    //}

    .slick-arrow {
        top: 51%;
        width: 30px;
        height: 30px;
        transform: none;

        svg {
            width: 13px;
            height: 28px;
        }
    }

    .slick-next {
        right: 0;
    }

    .slick-prev {
        left: 0;
    }

    .banner,
    .innerbanner {
        padding-top: 0;
    }

    .section--front__news {
        padding-top: 15px;
    }
}
