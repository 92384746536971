.taxonomy-taxonomies_vacatures-data {
    .cta {
        padding-bottom: 0;
    }
}

.cta {
    //margin-top: 86px;
    padding-bottom: 114px;

    h2 {
        font-size: 41px;
        font-weight: 400;
        margin-bottom: 40px;
    }
}

@media screen and (max-width: 991px) {
    .cta {
        //margin-top: 40px;
        padding-bottom: 40px;

        h2 {
            font-size: 25px;
        }
    }
}

@media screen and (max-width: 767px) {
    .cta {
        h2 {
            margin-bottom: 20px;
        }
    }
}
