// Location detail
.page-wrap--location {
    &::before {
        display: none;
    }
}

.locationgallery {
    position: relative;
    display: flex;
    padding: 80px 0;

    &.location__slide__none {
        .slick-list,
        .slick-track {
            width: 100% !important;
        }
    }
}

.locationgallery__item {
    position: relative;
    padding: 0 18px;

    img {
        border-radius: $border-radius;
    }
}

[class*="wpgmp_display_map_"] {
    height: 100% !important;
}

.locationgallery__item__play {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    transform: translate(-50%, -50%);
    pointer-events: none;
    border-radius: 50%;
    background-color: $primarycolor;

    svg {
        position: relative;
        left: 2px;
        width: 35px;
        height: 29px;
        color: #fff;
    }
}

.locationgallery .slick-arrow {
    top: 50%;
    width: 58px;
    height: 116px;
    background: $primarycolor;
}

.locationgallery .slick-prev {
    left: 0;
    border-top-right-radius: 116px;
    border-bottom-right-radius: 116px;

    svg {
        transform: translateX(-50%);
    }
}

.locationgallery .slick-next {
    right: 0;
    border-top-left-radius: 116px;
    border-bottom-left-radius: 116px;

    svg {
        transform: translateX(50%);
    }
}

.video--play {
    &::before {
        position: absolute;
        z-index: 0;
        top: 50%;
        left: 50%;
        display: inline-block;
        width: 60%;
        height: 60%;
        content: "";
        transform: translate(-50%, -50%);
        background: $white;
    }

    &,
    &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        display: inline-block;
        width: 57px;
        height: 57px;
        content: "";
        transform: translate(-50%, -50%);
        background: url(../../svg/play-circle-red.svg) no-repeat;
    }
}

.testimonial {
    position: relative;
    margin: 50px 0;

    &::before {
        position: absolute;
        z-index: -1;
        bottom: 0;
        left: 0;
        display: inline-block;
        width: 11.3vw;
        height: 33.6vw;
        content: "";
        pointer-events: none;
        background: url(../../svg/shapes-left.svg) no-repeat left top;
        background-size: contain;
    }
}

.testimonial__title {
    margin-bottom: 40px;
    font-size: 32px;
    font-weight: 600;
    text-align: center;
    color: $primarycolor;
}

.testimonial__item {
    padding: 0 22px;
}

.testimonial__item__inner {
    padding: 45px;
    font-size: 22px;
    font-weight: 400;
    text-align: center;
    color: #464646;
    border: 1px solid #d5d5d5;
    border-radius: $block-radius;
    background: $white;
}

.testimonial .slick-dots li button {
    width: 15px;
    height: 15px;
    font-size: 0;
    border: 2px solid $primarycolor;
    border-radius: 100%;

    &::before {
        display: none;
    }
}

.testimonial .slick-dots {
    position: static;
    margin: 17px 0;
}

.testimonial .slick-dots li.slick-active button {
    background: $primarycolor;
}

.testimonial .container-fluid {
    max-width: 1615px;
}

.childcare__wrapper {
    display: flex;
    align-items: center;
    padding: 50px 0;
}

.childcare__image {
    overflow: hidden;
    width: 17vw;
    min-width: 17vw;
    height: 17vw;
    margin-right: 85px;
    border-radius: 100%;

    img {
        width: 100%;
        transition: all 0.3s linear;
    }

    &:hover {
        img {
            transform: scale(1.05);
        }
    }
}

.childcare__title {
    font-size: 41px;
    font-weight: 500;
    line-height: normal;
    color: $secondarycolor;
}

.childcare__url {
    margin-top: 25px;
}

.childcare .container-fluid {
    max-width: 1310px;
}

//.content {
//    background: $white;
//    box-shadow: 5px 5px 15px rgba($black, 0.2);
//    border-radius: 50px;
//    padding: 95px;
//    transform: translateY(-122px);
//}

.loc-content .section {
    padding-top: 0;
    padding-bottom: 0;
}

.left-bg {
    &::before {
        position: absolute;
        z-index: 0;
        top: 180px;
        left: 0;
        display: inline-block;
        width: 15vw;
        height: 41.6vw;
        content: "";
        pointer-events: none;
        background: url(../../svg/shapes-left.svg) no-repeat left top;
        background-size: contain;
    }
}

// Accordian
.accordion .card {
    border: none;
    border-bottom: 1px solid #ddd !important;
    border-radius: 0;
}

.accordion .card-header {
    padding: 19px 0;
    font-weight: 500;
    border: none;
    background: none;

    a {
        position: relative;
        display: block;
        padding-right: 60px;

        &::before {
            position: absolute;
            top: 0;
            right: 0;
            width: 54px;
            height: 39px;
            content: "";
            border: 1px solid #efefef;
            border-radius: $border-radius;
        }

        &::after {
            position: absolute;
            top: 0;
            right: 0;
            width: 54px;
            height: 39px;
            content: '';
            transition: all 0.3s ease;
            background: url(../../svg/angle-up-solid-blue.svg) no-repeat center;
            background-size: 15px 30px;
        }

        &.collapsed::after {
            transform: rotate(180deg);
        }
    }
}

.accordion .card-body {
    padding: 0 0 20px;
    font-size: 20px;

    p {
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.accordion .section__title {
    font-size: 32px;
    font-weight: 600;
    text-align: left;
    color: $primarycolor;
}

.icon--back {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-right: 12px;
    background: url(../../svg/chevron-left-white.svg) no-repeat center;
    background-size: 10px 20px;
}

.location__testimonial {
    position: relative;
    max-width: 790px;
    margin: 80px 0;

    &--mobile {
        display: none;
    }
}

.location__testimonial,
.location__testimonial .section__leftcol {
    padding: 0;
}

.location__testimonial .section__content {
    margin: 0 0 20px;
    padding-right: 20px;
    font-weight: 300;
    font-style: italic;
}

.location__testimonial .section__heading {
    margin: 0;
    font-size: 25px;
    line-height: normal;
}

.location__testimonial .section__image {
    display: block;
    width: 170px;
    min-width: 170px;
    height: 170px;
    margin-left: 20px;
}

.locations__content {
    h1 {
        text-align: center;
    }

    .wpgmp_map_container {
        .wpgmp_filter_wrappers {
            margin-top: 50px;
            margin-bottom: 70px;
        }
    }
}

.locations__content__txt {
    text-align: center;
}

.banner {
    .wpgmp-control-outer,
    .pac-target-input {
        display: none;
    }
}

.wpgmp_map_container {
    .wpgmp_filter_wrappers {
        display: flex;
        justify-content: center;
        float: none;
        margin-bottom: 23px;
        padding: 0;
    }

    .fc-item-default {
        width: 100%;
    }

    .location_listing1 {
        display: flex;
        float: none !important;
    }

    .location_pagination1 {
        display: none;
        float: none !important;
    }

    .wpgmp_listing_container {
        display: flex;
        float: none;
    }

    div.wpgmp_search_form input.wpgmp_search_input {
        height: 72px;
        padding: 14px 26px;
        font-size: 22px;
        font-weight: 300;
        color: $body-font-color;
        border: 1px solid #f2f2f2;
        border-radius: 10px;
        background-color: #f2f2f2;

        &:focus {
            border-color: #f2f2f2;
        }
    }

    div.categories_filter {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        float: none;
        margin: 0;

        .wpgmp_search_form {
            order: 2;
            margin-bottom: 17px;

            input {
                width: 160px;
                margin-right: 18px;
            }
        }

        div[data-container=wpgmp-filters-container] {
            width: calc(50% - 18px);

            select {
                width: 100%;
                max-width: 100%;
                margin-right: 0;
            }
        }

        .select-wrapper-radius {
            position: relative;
            order: 3;

            select {
                min-width: 240px;
                padding-left: 53px;
            }

            &::before {
                position: absolute;
                z-index: 1;
                top: 26px;
                left: 22px;
                width: 21px;
                height: 21px;
                content: '';
                background-image: url('../../svg/location-regular.svg');
                background-repeat: no-repeat;
                background-size: 21px 21px;
            }
        }

        select {
            position: relative;
            width: calc(50% - 18px);
            min-width: calc(50% - 18px);
            height: 72px;
            margin-top: 0;
            margin-right: 18px;
            margin-bottom: 17px;
            padding: 14px 47px 14px 26px;
            font-size: 22px;
            font-weight: 300;
            text-transform: none;
            color: $body-font-color;
            border: 1px solid #f2f2f2;
            border-radius: 10px;
            background-color: #f2f2f2;
            background-image: url('../../svg/angle-down-light-green.svg');
            background-position: center right 21px;
            background-size: 16px 32px;
            -webkit-appearance: none;

            &:focus {
                border-color: #f2f2f2;
                outline: none;
            }
        }
    }

    div.wpgmp_search_form::after {
        display: none;
    }

    div.wpgmp_listing_header div.wpgmp_search_form {
        margin-top: 0;
    }

    .wpgmp_listing_header {
        display: none;
    }

    .wpgmp_before_listing {
        display: none;
    }

    div.wpgmp_categories {
        display: flex;
        float: none;
        height: auto !important;
    }

    .location__block {
        display: flex;
        width: 49.5%;
        margin-right: 1%;
        margin-bottom: 15px;
        padding: 21px 25px;
        color: $body-font-color;
        border: 1px solid #c6c6c6;
        border-radius: 10px;

        &:nth-child(2n+2) {
            margin-right: 0;
        }

        &:hover {
            .location__block__arrow {
                &::after {
                    right: -3px;
                }
            }
        }
    }

    .BSO {
        .location__block__title {
            color: #64c2c7;
        }
    }

    .Kinderdagverblijf {
        .location__block__title {
            color: #36638a;
        }
    }

    .Peuteropvang {
        .location__block__title {
            color: $primarycolor;
        }
    }

    .skos {
        .location__block__title {
            color: #1c79c6;
        }

        .location__block__logo {
            opacity: 1;

            &::before {
                background-image: url('../../images/logo-skos.png');
            }
        }
    }

    .haarlem {
        .location__block__logo {
            opacity: 1;
        }
    }

    .location__block__title {
        display: block;
        font-size: 20px;
        font-weight: 500;
        line-height: 1.2;
        color: $primarycolor;
    }

    .location__block__content {
        flex: 1;
        padding-left: 24px;
    }

    .location__block__logo {
        display: flex;
        width: 54px;
        height: 54px;
        transition: all 0.3s;

        &::before {
            width: 54px;
            height: 54px;
            content: '';
            background-image: url('../../images/logo-hero.png');
            background-repeat: no-repeat;
            background-size: 54px 54px;
        }
    }

    .location__block__txt {
        display: flex;
        flex-direction: column;
        font-size: 16px;
    }

    .location__block__bottom {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .location__block__distance {
        position: relative;
        padding-right: 9px;
        padding-left: 23px;
        font-weight: 500;

        &::before {
            position: absolute;
            top: 2px;
            left: 0;
            width: 16px;
            height: 16px;
            content: '';
            background-image: url('../../svg/location-regular.svg');
            background-repeat: no-repeat;
            background-size: 16px 16px;
        }
    }

    .location__block__address {
        position: relative;
        flex: 1;
        padding-left: 19px;
        font-weight: 300;

        &::before {
            position: absolute;
            top: 2px;
            left: 0;
            width: 12px;
            height: 16px;
            content: '';
            background-image: url('../../svg/map-marker-alt-regular.svg');
            background-repeat: no-repeat;
            background-size: 12px 16px;
        }
    }

    .location__block__arrow {
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: row;
        margin-left: auto;
        padding-right: 26px;
        padding-left: 20px;
        font-size: 20px;
        font-weight: 500;
        color: $body-font-color;

        &::after {
            position: absolute;
            top: 50%;
            right: 0;
            width: 13px;
            height: 23px;
            content: '';
            transition: all 0.3s ease;
            transform: translateY(-50%);
            background-image: url('../../svg/angle-right-regular-grey.svg');
            background-repeat: no-repeat;
            background-size: 13px 23px;
        }
    }

    .fc-component-6 {
        display: flex;
        flex-wrap: wrap;
    }

    .btn--zoeklocaties {
        order: 4;
        margin-bottom: 17px;
        padding: 20px 32px;
    }
}

.gm-style .gm-style-iw {
    top: 13px !important;
    max-width: 278px !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 48px;
    border: 2px solid $primarycolor;
    border-radius: 10px;

    .gm-ui-hover-effect {
        top: 0 !important;
        right: 0 !important;
        display: flex !important;
        align-items: center;
        justify-content: center;
        opacity: 1;
        border-radius: 0 0 0 10px;
        background-color: $primarycolor !important;

        &::after {
            width: 16px;
            height: 16px;
            content: '';
            background: url(../../svg/times-light-white.svg) no-repeat;
            background-size: 16px 16px;
        }

        img {
            display: none !important;
        }
    }

    &.BSO {
        border-color: #64c2c7;

        .gm-ui-hover-effect {
            background-color: #64c2c7 !important;
        }

        .loc__marker__btn {
            color: #64c2c7;
        }
    }

    &.Kinderdagverblijf {
        border-color: #36638a;

        .gm-ui-hover-effect {
            background-color: #36638a !important;
        }

        .loc__marker__btn {
            color: #36638a;
        }
    }

    &.Peuteropvang {
        border-color: $primarycolor;

        .gm-ui-hover-effect {
            background-color: $primarycolor !important;
        }

        .loc__marker__btn {
            color: $primarycolor;
        }
    }
}

.gm-style .gm-style-iw-t {
    &::after {
        top: 12px;
        border: 2px solid $primarycolor;
        border-top: 0;
        border-right: 0;
    }
}

.loc__marker__inner {
    padding-top: 14px;
}

.loc__marker__btn {
    display: block;
    margin-top: 10px;
    color: $primarycolor;
}

.page-wrap__inner--location {
    .accordion {
        margin-top: 50px;

        .section__title {
            margin-bottom: 23px;
        }
    }
}

.location__map {
    width: 100%;
    height: 60vh;
    margin-top: 0;

    iframe {
        width: 100% !important;
        height: 100% !important;
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .location__map__click {
        position: relative;
        cursor: pointer;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: "";
            background-color: rgba(0, 0, 0, 0.4);
        }
    }
}

@media screen and (max-width: 1599px) {
    .location__testimonial {
        margin-bottom: 70px;
    }

    .accordion .card-header {
        padding: 15px 0;

        a {
            &::before,
            &::after {
                width: 50px;
                height: 35px;
            }
        }
    }

    .locationgallery {
        padding: 50px 0;
    }

    .accordion .card-body {
        font-size: 18px;
    }

    .testimonial__title,
    .accordion .section__title {
        font-size: 30px;
    }

    .childcare__image {
        margin-right: 70px;
    }

    .childcare__title {
        font-size: 35px;
    }

    .testimonial__item {
        padding: 0 20px;
    }

    .testimonial__item__inner {
        padding: 40px;
        font-size: 20px;
    }

    .locationgallery .slick-arrow {
        width: 50px;
        height: 100px;
    }
}

@media screen and (max-width: 1199px) {
    .location__testimonial {
        margin: 60px 0;
    }

    .left-bg {
        &::before {
            display: none;
        }
    }

    .accordion .card-header {
        padding: 10px 0;

        a {
            &::before {
                width: 40px;
                height: 30px;
                border-radius: 6px;
                background-size: 12px 24px;
            }

            &::after {
                width: 40px;
                height: 30px;
                background-size: 12px 24px;
            }

            &.collapsed::after {
                background-size: 12px 24px;
            }
        }
    }

    .accordion .card-body {
        font-size: 16px;
    }

    .icon--back {
        transform: translateY(2px);
    }

    .loc-ontent {
        padding: 50px;
        transform: translateY(-70px);
        border-radius: 30px;
    }

    .testimonial__title,
    .accordion .section__title {
        font-size: 25px;
    }

    .childcare__image {
        margin-right: 60px;
    }

    .childcare__title {
        font-size: 30px;
    }

    .video--play {
        &,
        &::after {
            width: 45px;
            height: 45px;
        }
    }

    .testimonial {
        &::before {
            display: none;
        }
    }

    .testimonial__item {
        padding: 0 15px;
    }

    .testimonial__item__inner {
        padding: 30px;
        font-size: 18px;
    }

    .locationgallery .slick-arrow {
        width: 40px;
        height: 80px;
    }
}

@media screen and (max-width: 991px) {
    .location__testimonial {
        &--desktop {
            display: none;
        }

        &--mobile {
            display: block;
        }

        .section__image {
            margin-top: 50px;
        }
    }

    .wpgmp_filter_wrappers {
        margin-bottom: 25px !important;
    }

    .accordion .card-body {
        font-size: 15px;
    }

    .testimonial__title,
    .accordion .section__title {
        margin-bottom: 10px;
        font-size: 23px;
    }

    .childcare__image {
        margin-right: 50px;
    }

    .childcare__title {
        font-size: 25px;
    }

    .video--play {
        &,
        &::after {
            width: 35px;
            height: 35px;
        }
    }

    .testimonial__item {
        padding: 0 10px;
    }

    .testimonial__item__inner {
        padding: 20px;
        font-size: 16px;
    }

    .locationgallery .slick-arrow {
        width: 35px;
        height: 70px;
    }
}

@media screen and (max-width: 767px) {
    .location__map {
        margin-top: 20px;
    }

    .banner__location {
        .wpgmp_map {
            height: 400px !important;
        }
    }

    .sidebar--location {
        display: flex;
        flex-direction: column;
    }

    .sidebar__links {
        order: 12;
        margin-bottom: 0;
    }

    .loc-content {
        transform: none;
        box-shadow: none;
    }

    .childcare__image {
        width: 200px;
        min-width: 200px;
        height: 200px;
        margin: 0 0 40px;
    }

    .testimonial__item__inner {
        font-size: 15px;
    }

    .childcare__title {
        font-size: 20px;
    }

    .childcare__wrapper {
        flex-direction: column;
        padding-top: 10px;
        text-align: center;
    }

    .location__testimonial {
        flex-direction: column-reverse;
        margin-top: 0;
        margin-bottom: 30px;
        padding: 30px !important;
        border: 1px solid #d5d5d5;
        border-radius: $block-radius;

        .section__image {
            position: absolute;
            top: 20px;
            width: 70px;
            min-width: 0;
            height: 70px;
            margin-top: 0;
            margin-left: 0;

            &::before {
                display: none;
            }
        }

        .section__content {
            order: 3;
            margin: 35px 0 0;
        }

        .section__heading {
            padding-left: 85px;
            font-size: 14px;
            color: $primarycolor;
        }

        .section__date {
            padding-left: 85px;
        }

        .section__leftcol {
            display: flex;
            flex-direction: column;
        }
    }

    .accordion .card-body {
        font-size: 14px;
    }

    .accordion > .card .card-header {
        line-height: 2;
    }

    .locationgallery .slick-arrow {
        width: 30px;
        height: 60px;
        transform: translateY(-50%);
    }

    .locationgallery__item__play {
        width: 32px;
        height: 32px;

        svg {
            width: 17px;
            height: 14px;
        }
    }

    .testimonial .slick-dots {
        margin-bottom: 30px;
    }

    .locationgallery {
        padding: 0;
    }
}

@media screen and (max-width: 575px) {
    .wpgmp_map_parent {
        margin-top: 99px;
    }
}
